/*Table of contents
* Base styles
* Paddings and Margins
* Common Styles
* Intro Section Flex Slider
* Top Line
* Header Styles
* Main Menu Styles
* jQuery Plugins
* Breadcrumbs
* Home Page content
* Footer
* Widgets
* Copyright
* Faq section
* About Page
* 404 Page
* Terms
* Team
* Services
* Gallery Fullwidth
* Gallery Regular
* Gallery Extended
* Calendar
* Appointment
* Blog
* Blog Sidebar
* Blog Post
* Blog Video Post
* Team Member
* Courses
* Calculator
* Typography
* Elements
*
*/

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a:hover {
  text-decoration: none;
  -webkit-transition: all 0.15s linear 0s;
  -moz-transition: all 0.15s linear 0s;
  -o-transition: all 0.15s linear 0s;
  -ms-transition: all 0.15s linear 0s;
  transition: all 0.15s linear 0s;
}

a:focus {
  outline: medium none;
  text-decoration: none;
}

/* ==========================================================================
  Template styles
   ========================================================================== */
/*parallax*/
.parallax {
  background-attachment: fixed !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-position: 50% 0;
  background-repeat: no-repeat;
  position: relative;
}

@media (max-width: 767px) {
  .parallax {
    -webkit-background-size: auto auto !important;
    -moz-background-size: auto auto !important;
    -o-background-size: auto auto !important;
    background-size: auto auto !important;
  }
}

/*page preloader*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 13000;
  height: 100%;
}

.preloader_image {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  background: url(../img/preloader.gif) no-repeat 50% 50% transparent;
  margin: -50px 0 0 -50px;
}

/*
animated elements
*/
.to_animate {
  visibility: hidden;
}

.animated {
  /*IE7 fix*/
  visibility: visible !important;
}

/*video placeholder*/

.embed-responsive-3by2 {
  padding-bottom: 66.666%;
}

.embed-placeholder:before {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  content: "\e696";
  font-family: "rt-icons-2";
  position: absolute;
  color: #ffffff;
  font-size: 30px;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -5px;
}

.embed-placeholder:hover:before {
  opacity: 0.4;
}

/*
**Paddings and Margins
*/
.padding-v100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.padding-v120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.padding-v60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.padding-60 {
  padding: 60px;
}

.padding-r20 {
  padding-right: 20px;
}

.margin-v0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@media (min-width: 768px) {
  .padding-l0-768 {
    padding-left: 0;
  }

  .padding-r0-768 {
    padding-right: 0;
  }
}

.padding-g0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-v0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.margin-v20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.margin-t25 {
  margin-top: 25px !important;
}

.margin-t0 {
  margin-top: 0 !important;
}

.margin-b8 {
  margin-bottom: 8px !important;
}

.margin-b0 {
  margin-bottom: 0 !important;
}

.margin-b100 {
  margin-bottom: 100px !important;
}

.margin-b60 {
  margin-bottom: 60px !important;
}

.margin-b45 {
  margin-bottom: 45px !important;
}

.margin-b30 {
  margin-bottom: 30px !important;
}

.margin-b20 {
  margin-bottom: 20px !important;
}

.margin-t0 {
  margin-top: 10px !important;
}
/*
**End of Paddings and Margins
*/

/*
** Common styles
*/
.boxed .container {
  max-width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 500px) {
  .boxed .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #666566;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

@media (min-width: 1200px) {
  .page_topline,
  .header-top {
    padding: 0 80px;
  }
}

.grand-hotel {
  font-family: "Grand Hotel", cursive;
  font-size: 30px;
  color: #818181;
}
.poppins {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -1px;
  color: #9cc0e5;
  line-height: 50px;
  margin: 5px 0;
  word-wrap: break-word;
}

.pink {
  color: #f5abcb;
  font-size: inherit;
  font-weight: inherit;
}

.blue {
  color: #9cc0e5;
}

p {
  line-height: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0;
}

h1 {
  font-size: 50px;
}

.default-text {
  color: #666566;
  font-weight: 300;
}

.default-color {
  color: #666566;
}

.ordered-list {
  font-weight: 700;
}

.ordered-list p {
  margin-left: 5px;
}

.half-width {
  width: 50%;
  float: left;
}

a:hover {
  cursor: pointer;
}

.alignright {
  float: right;
  margin: 5px 0 15px 30px;
  max-width: 50%;
}

@media (max-width: 767px) {
  .alignright,
  .alignleft {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
}

.autowidth {
  width: auto !important;
}

.float-video {
  width: 370px;
  height: 247px;
}

.large-icon {
  font-size: 48px;
}

.xlarge-icon {
  font-size: 96px;
}

h1.poppins {
  margin-bottom: 50px;
}

.btn-type1.light-bg {
  border-color: transparent;
  background-color: #f0f1eb;
}

.btn-type1.dark-bg,
.btn-type1.pink-bg,
.btn-type1.blue-bg,
.btn-type1.green-bg,
.btn-type1.turquoise-bg {
  color: #fff;
  border-color: transparent;
}

.btn-type1.dark-bg {
  background-color: #666566;
}

.btn-type1.pink-bg {
  background-color: #f5abcb;
}

.btn-type1.blue-bg {
  background-color: #9cc0e5;
}

.btn-type1.green-bg {
  background-color: #c2dc71;
}

.btn-type1.turquoise-bg {
  background-color: #9ed7c8;
}

.btn-type1.dark-bg:hover {
  background-color: #f5abcb;
}

.btn-type1.small-btn {
  width: 120px;
  height: 40px;
  line-height: 36px;
}

.reset-bg,
.reset-bg:before {
  background: none;
}

.login-btn,
.register-btn,
.login-btn:hover,
.register-btn:hover,
.searchform button,
.searchform .form-group:hover button,
.flex-direction-nav a:hover:before,
.flex-direction-nav a:before,
.owl-dot span,
.owl-dot.active span,
.owl-dot span:hover,
.links-wrap h4,
.links-wrap h4:hover,
.carousel-indicators li,
.carousel-indicators li:hover,
.carousel-indicators li.active,
.btn-type1,
.btn-type1:hover,
.ui-datepicker-trigger,
.ui-datepicker-trigger:hover,
button,
button:hover,
.widget_flickr ul li:before,
.widget_flickr ul li:hover:before {
  -webkit-transition: 200ms;
  -moz-transition: 200ms;
  -o-transition: 200ms;
  transition: 200ms;
}
/*
** End of Common styles
*/

/*
** Intro Section Flex Slider
*/
.demo_parallax {
  /* background-image: url('../images/parallax/map.jpg'); */
}

.intro_section,
.team {
  position: relative;
  overflow: hidden;
}

.intro_section > img .team > img {
  width: 100%;
}

.intro_section .flexslider:before,
.intro_section .flexslider:after,
.team .flexslider:before,
.intro_section .flexslider:after {
  content: "";
  display: block;
  clear: both;
}

.intro_section .embed-placeholder > img,
.team .embed-placeholder > img {
  max-width: 510px;
  display: inline-block !important;
}

.intro_section .container,
.team .container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.intro_section [class*="col-"],
.team [class*="col-"] {
  position: static;
}

.intro_section .slide_description_wrapper,
.team .slide_description_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  max-width: 1170px;
  font-size: 0;
  z-index: 5;
  padding: 20px 15px 20px;
}

@media (min-width: 600px) {
  .boxed .intro_section .slide_description_wrapper,
  .boxed .team .slide_description_wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.intro_section .slide_description_wrapper:before,
.intro_section .slide_description,
.team .slide_description_wrapper:before,
.team .slide_description {
  display: inline-block;
  vertical-align: middle;
}

.intro_section .slide_description_wrapper:before,
.team .slide_description_wrapper:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}

.intro_section .slide_description > div,
.team .slide_description > div {
  margin: 0;
}

@media (max-width: 991px) {
  .intro_section,
  .team {
    line-height: 1;
    text-align: center;
  }
  .intro_section .nav-container,
  .team .nav-container {
    padding: 0;
  }
  .intro_section .flex-control-nav,
  .intro_section .flex-custom-nav,
  .team .flex-control-nav,
  .team .flex-custom-nav {
    bottom: 13px;
    text-align: center;
  }
  .intro_section .container,
  .team .container {
    position: static;
    width: auto;
    max-width: 100%;
  }
  .intro_section .slide_description_wrapper:before,
  .team .slide_description_wrapper:before {
    display: none;
  }
  .intro_section .slide_description,
  .team .slide_description {
    padding: 20px 20px 30px;
    display: block;
  }
  .intro_section .slide_description_wrapper,
  .intro_section .slide_description_wrapper {
    padding: 0;
    position: static;
  }
}

.intro_section .slide_description {
  position: relative;
  top: -15px;
  width: 50%;
  padding: 50px 70px;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
}

@media (max-width: 991px) {
  .intro_section {
    margin-bottom: 150px;
  }

  .intro_section .slide_description {
    top: 0;
    width: 100%;
    margin: auto;
    margin-top: 20px;
    max-width: 570px;
  }
}

.intro_section .flex-direction-nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
}

.intro_section .flex-direction-nav a:hover:before {
  color: #818181;
}

.btn-type1,
a.btn-type1 {
  margin-top: 14px;
  color: #dfdfdf;
  width: 170px;
  height: 60px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 56px;
  background-color: #3d6ff8;
}

.btn-type1:hover,
.btn-type1:focus,
.btn-type1:active,
a.btn-type1:hover,
a.btn-type1:focus,
a.btn-type1:active {
  color: #fff;
  background-color: #5814d8;
}

/* ==========
   Top line
   ========== */

.page_topline {
  color: white;
  background-color: #666565;
}

.page_topline .row > .col-sm-4 {
  max-height: 60px;
}

.page_topline i {
  font-size: 24px;
}

@media (max-width: 767px) {
  .page_topline .row > div {
    text-align: center;
  }
}

/*
Login controls
*/
.login-controls,
.address {
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  height: 60px;
}

@media (max-width: 1200px) {
  .address {
    font-size: 14px;
  }
}

.login-controls .dropdown,
.register-btn {
  float: left;
  padding: 18px 0;
}

.login-controls .dropdown:nth-of-type(2) {
  margin-left: 12px;
}

.login-controls i {
  vertical-align: text-top;
  margin-right: 4px;
}

.login-btn {
  padding-right: 20px;
  border-right: 1px solid #767575;
}

.register-btn {
  max-height: 60px;
  margin-left: 12px;
}

.login-btn i {
  color: #f5abcb;
}

.register-btn i {
  color: #9cc0e5;
}

.login-controls a {
  color: #fff;
}

.login-btn:hover {
  color: #f5abcb;
}

.register-btn:hover {
  color: #9cc0e5;
}

/*
Login Form
*/
.login-controls .dropdown {
  position: static;
  z-index: 1001;
  max-height: 60px;
}

.login-controls .dropdown a {
  cursor: pointer;
}

.login-controls .dropdown.open .login-form-wrap {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: block;
  /*height: 500px;*/
  z-index: 1001;
}

.login-controls .dropdown-menu {
  max-width: 400px;
  width: 100%;
  height: 340px;
  margin: auto;
  margin-top: 1px;
  padding: 40px;
  font-size: 16px;
  font-weight: 300;
  background-color: #666565;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: none;
}

@media (min-width: 767px) {
  .login-controls .dropdown-menu {
    width: 400px;
  }
}

.login-controls .dropdown.open .dropdown-menu {
  display: block;
  position: relative;
  top: auto;
  float: none;
  margin: auto;
}

.login-controls input:not([type="checkbox"]) {
  padding: 12px 30px;
  margin-bottom: 10px;
  height: 60px;
  font-style: italic;
  color: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

.login-controls input[type="password"] {
  margin-bottom: 15px;
}

.login-controls input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.login-controls input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.login-controls input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.login-controls input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.login-controls .checkbox {
  float: right;
  padding: 0;
  margin-left: 5px;
}

@media (max-width: 370px) {
  .login-controls .dropdown-menu {
    height: 384px;
  }

  .login-controls .checkbox {
    float: none;
    margin-top: 15px;
  }
}

.login-controls .forg-pass {
  color: #9cc0e5;
}

.login-controls .forg-pass:hover {
  color: #fff;
}

.checkbox input:not(checked) {
  position: absolute;
  margin-left: 0;
  left: 19px;
  opacity: 0;
}

.checkbox input + label {
  padding: 0;
  font-weight: 300;
}

.checkbox input + label:before {
  content: "";
  position: relative;
  right: 5px;
  top: -2px;
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 14px;
  font-family: "rt-icons-2" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-align: center;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f5abcb;
  border: 1px solid rgba(255, 255, 255, 0.1);
  vertical-align: middle;
}

.checkbox input:checked + label:before {
  content: "\e6f4";
}

.login-controls .btn-type1 {
  margin-top: 35px;
  color: #fff;
  font-weight: 500;
  border-color: #fff;
  background-color: transparent;
}

.login-controls .btn-type1:hover {
  color: #666565;
  background-color: #fff;
}

.login-controls .dropdown-menu
    /*
    End of Login Form
    */
.login-btn:hover,
.register-btn:hover {
  cursor: pointer;
}

button:focus {
  outline: none;
}
/*
End of Login controls
*/

/*
Search form
*/
.searchform {
  max-width: 370px;
  margin: auto;
}

.searchform .form-group {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  font-size: 0;
}

.searchform .form-control {
  height: 60px;
  width: 100%;
  font-style: italic;
  color: #cbcaca;
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.searchform .form-control:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.searchform button {
  color: #c2dc71;
  vertical-align: middle;
  background: none;
  outline: none;
  border: none;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  font-size: 0;
}

.searchform button:before {
  font-size: 24px;
}

.searchform button:hover {
  color: #fff;
}

.searchform .form-group:hover ::-webkit-input-placeholder,
.searchform :focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: 200ms;
  -o-transition: 200ms;
  transition: 200ms;
}
.searchform .form-group:hover ::-moz-placeholder,
.searchform :focus::-moz-placeholder {
  color: transparent;
  -moz-transition: 200ms;
  transition: 200ms;
}
.searchform .form-group:hover :-moz-placeholder,
.searchform :focus:-moz-placeholder {
  color: transparent;
  -moz-transition: 200ms;
  transition: 200ms;
}
.searchform .form-group:hover :-ms-input-placeholder,
.searchform :focus:-ms-input-placeholder {
  color: transparent;
}
.searchform ::-webkit-input-placeholder {
  -webkit-transition: 200ms;
  -o-transition: 200ms;
  transition: 200ms;
}
.searchform ::-moz-placeholder {
  -webkit-transition: 200ms;
  -o-transition: 200ms;
  transition: 200ms;
}
.searchform :-moz-placeholder {
  -webkit-transition: 200ms;
  -o-transition: 200ms;
  transition: 200ms;
}
/*
End of Search form
*/

/*
address
*/
.address {
  line-height: 60px;
}

.address i {
  vertical-align: text-top;
  font-weight: normal;
  color: #9ed7c8;
}
/*
End of address
*/

/* ==========
   End Top line
   ========== */

/*
** Header
*/
.page_header_wrapper {
  min-height: 60px;
}
.page_header {
  -webkit-transition: background 0.2s ease-in-out 0s;
  -moz-transition: background 0.2s ease-in-out 0s;
  -o-transition: background 0.2s ease-in-out 0s;
  -ms-transition: background 0.2s ease-in-out 0s;
  transition: background 0.2s ease-in-out 0s;
  top: 0;
  left: 0;
  right: 0;
  position: relative;
  z-index: 1000;
}

.page_header.mobile-active {
  z-index: 1002;
}

.page_header.affix {
  position: fixed;
}

.header_white {
  color: #666565;
  background-color: #ffffff;
}

.header_white.affix {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}

/* modal search */
.modal-open .page_header_wrapper,
.modal-open .page_header.affix,
.modal-open .modal#search_modal {
  right: 16px !important;
}

#search_modal {
  color: #fff;
}

#search_modal a {
  color: #ffffff;
  opacity: 0.6;
  font-size: 12px;
  text-transform: uppercase;
}

#search_modal a:hover {
  color: #f5abcb;
  opacity: 1;
}

#search_modal .searchform-respond {
  width: 300px;
  margin: 20px auto;
}

#search_modal .form-control {
  border: none;
  background-color: transparent;
  height: 60px;
  color: #ffffff;
}

#search_modal .theme_button {
  padding: 18px;
}

#search_modal .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
  opacity: 1;
}

#search_modal .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
  opacity: 1;
}

#search_modal .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

#search_modal .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

/*
Logo
*/
.header-top {
  background-color: #f0f1eb;
}

.top-logo {
  margin: 25px 0;
  width: 188px;
}

.page_header.affix .top-logo {
  width: 127px;
  /*height: 61px;*/
  margin: 10px 0 9px;
}
/*
End of Logo
*/

/*
Social icons
*/
.page_header .social-icons {
  display: inline-block;
  margin: 55px 0;
  width: auto;
}

.page_header .lenguage-icons {
  display: flex;
  justify-content: center;
  margin: 55px 0;
  width: auto;
}

.page_header.affix .social-icons .lenguage-icons {
  margin: 25px 0;
}

a.color-bg-icon {
  color: #f0f1eb;
  background-color: #323232;
  padding: 10px;
}

a.color-bg-icon:before {
  vertical-align: middle;
}

div.color-bg-icon {
  color: #f0f1eb;
  background-color: #323232;
  padding: 10px;
}

div.color-bg-icon:before {
  vertical-align: middle;
}

header a.social-icon {
  font-size: 14px;
}

header div.social-icon {
  font-size: 14px;
}

a[class*="soc-"].rounded-icon,
a.rt-icon2-youtube5.rounded-icon {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  opacity: 0.6;
}

div[class*="soc-"].rounded-icon,
div.rt-icon2-youtube5.rounded-icon {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  opacity: 0.6;
}

a.rt-icon2-youtube5:before {
  font-size: 16px;
  vertical-align: text-top;
}

a.color-bg-icon:hover {
  background-color: #b6b3b3;
  color: #f0f1eb !important;
  opacity: 1;
}

div.color-bg-icon:hover {
  background-color: #d4d1d1;
  color: #f0f1eb !important;
  opacity: 1;
}

.social-icons a:not(:first-of-type) {
  margin-left: 1px;
}

div.soc-spanish {
  background-color: #666565;
}
div.soc-english {
  background-color: #666565;
}
a.soc-facebook {
  background-color: #507cbe;
}

a.soc-twitter {
  background-color: #00abef;
}

a.soc-google {
  background-color: #d93e2d;
}

a.soc-skype {
  background-color: #28abe3;
}

a.rt-icon2-youtube5 {
  background-color: #d12121;
}
/*
End of Social icons
*/

/*
Contact-info
*/
.contact-info {
  margin: 40px 0;
}

.page_header.affix .contact-info {
  margin: 20px 0;
}

.contact-number {
  font-size: 40px;
  font-weight: 900;
  letter-spacing: -2px;
  font-family: "Roboto", sans-serif;
  line-height: 40px;
  color: #9cc0e5;
}

.page_header.affix .contact-number {
  font-size: 30px;
  line-height: 40px;
}

.contact-number > span {
  font-family: "Roboto", sans-serif;
  color: #f5abcb;
}

.work-hours {
  font-size: 16px;
  line-height: 20px;
}

.page_header.affix .work-hours {
  display: none;
}
/*
End of Contact-info
*/

@media (max-width: 1199px) {
  .page_header .work-hours {
    display: none;
  }

  .page_header .contact-number {
    font-size: 30px;
    line-height: 40px;
  }

  .page_header .contact-info {
    margin: 20px 0;
  }

  .page_header .social-icons {
    margin: 25px 0;
  }

  .page_header .top-logo {
    width: 127px;
    height: 127px;
    margin: 10px 0 9px;
  }
}

@media (max-width: 767px) {
  .page_header.affix {
    position: static;
  }

  .page_header .row > div {
    text-align: center;
  }
}

@media (max-width: 991px) {
  .page_header.mobile-active .mainmenu li {
    padding: 5px 0;
  }
}

.top-logo,
.contact-info,
.contact-number {
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

/*
**main menu (min 992px) styles
*/

@media (min-width: 992px) {
  /* menu layout */
  .sf-menu,
  .sf-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .sf-menu li {
    position: relative;
  }

  .sf-menu ul {
    position: absolute;
    display: none;
    top: 100%;
    z-index: 99;
  }

  .sf-menu li:hover > ul,
  .sf-menu li.sfHover > ul {
    display: block;
  }

  .sf-menu a {
    display: block;
    position: relative;
  }

  .sf-menu ul ul {
    top: -20px;
    left: 100%;
  }

  /* menu skin */
  .sf-menu a {
    /*padding: .4em 1.1em;*/
    text-decoration: none;
    zoom: 1;
    /* IE7 */
    -webkit-transition-property: color, background-color, border-color;
    -moz-transition-property: color, background-color, border-color;
    -ms-transition-property: color, background-color, border-color;
    -o-transition-property: color, background-color, border-color;
    transition-property: color, background-color, border-color;
  }

  .sf-menu li {
    white-space: nowrap;
    /* no need for Supersubs plugin */
    *white-space: normal;
    /* ...unless you support IE7 (let it wrap) */
    -webkit-transition: background 0.2s;
    transition: background 0.2s;
  }

  .sf-menu > li {
    display: inline-block;
    position: relative;
  }

  .sf-menu > li > a {
    padding-top: 19px;
    padding-bottom: 19px;
  }

  .sf-menu > li > a:hover,
  .sf-menu > li.active > a {
    color: #f5abcb;
  }

  .bordered_items .sf-menu {
    text-align: center;
  }

  .bordered_items .sf-menu > li {
    padding: 0;
  }

  .bordered_items .sf-menu > li + li:before {
    content: "";
    width: 1px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .header_white .sf-menu > li > a {
    color: #666565;
  }

  /*.header_white .sf-menu > li > a:hover,*/
  /*.header_white .sf-menu > li.active > a {*/
  /*color: #f5abcb;*/
  /*}*/
  .header_white .sf-menu > li + li:before {
    background-color: #e1e1e1;
  }

  .header_darkgrey .sf-menu > li > a {
    color: #ffffff;
  }

  .header_darkgrey .sf-menu > li > a:hover,
  .header_darkgrey .sf-menu > li.active > a {
    color: #f5abcb;
  }

  .header_gradient .sf-menu > li > a {
    color: #ffffff;
  }

  .header_gradient .sf-menu > li > a:hover,
  .header_gradient .sf-menu > li.active > a {
    color: #323232;
  }

  .sf-menu li:hover,
  .sf-menu li.sfHover {
    /* only transition out, not in */
    -webkit-transition: none;
    transition: none;
  }

  /* second level */
  .sf-menu ul {
    margin-top: 1px;
    /*text-align: left;*/
    list-style: none;
    padding: 22px 0;
    background-color: #ffffff;
    /*box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);*/
    /*min-width: 12em;*/
    /* allow long menu items to determine submenu width */
    /**width: 12em;*/
    /* no auto sub width for IE7, see white-space comment below */
  }

  .sf-menu ul a {
    color: #666565;
    padding-left: 12.5%;
    padding-right: 12.5%;
  }

  /* third level */
  .sf-menu ul ul {
    margin-top: -2px;
  }
}

.toggle_menu {
  position: absolute;
  top: 0;
  right: 0;
  /*margin-left: -30px;*/
  display: none;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 1001;
  visibility: visible;
}

.header_white .toggle_menu span,
.header_white .toggle_menu span:before,
.header_white .toggle_menu span:after {
  background-color: #323232;
}

.page_toplogo .toggle_menu {
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  background-color: #323232;
  margin: 0;
}

.toggle_menu:hover span:before {
  top: -7px;
}

.toggle_menu:hover span:after {
  top: 7px;
}

.toggle_menu span,
.toggle_menu span:before,
.toggle_menu span:after {
  display: block;
  width: 24px;
  height: 2px;
  position: absolute;
  background-color: #ffffff;
  content: "";
  left: 0;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

.toggle_menu span {
  left: 18px;
  top: 50%;
  margin-top: -1px;
}

.toggle_menu span:before {
  top: -6px;
}

.toggle_menu span:after {
  top: 6px;
}

/*
**main menu mobile styles
*/
@media (max-width: 991px) {
  .mainmenu_wrapper {
    position: fixed;
    visibility: hidden;
    z-index: 20;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
    opacity: 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  .mobile-active .mainmenu_wrapper {
    visibility: visible;
    opacity: 1;
    background-color: #111;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .toggle_menu {
    display: block;
  }
  .toggle_menu.mobile-active {
    background-color: #323232 !important;
    border: none;
    left: auto;
    top: 0;
    right: 0;
    position: fixed;
    margin: 0;
    z-index: 3000;
  }
  .toggle_menu.mobile-active:after {
    position: absolute;
    /*top: 14px;*/
    left: 10px;
    font-family: "rt-icons-2";
    content: "\e117";
    font-size: 40px;
    color: #ffffff;
  }
  .toggle_menu.mobile-active span {
    display: none;
  }
  .nav-justified > li > a {
    text-align: left;
  }
  .sf-menu {
    position: fixed;
    overflow: auto;
    background-color: #323232;
    padding: 10px 20px 10px 10px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 100%;
    font-size: 1.1em;
    text-transform: uppercase;
    font-weight: 400;
    word-wrap: break-word;
    text-align: left;
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    opacity: 0;
  }
  .mobile-active .sf-menu {
    opacity: 1;
    right: 20px;
  }
  .sf-menu > li > a,
  .sf-menu a {
    color: #ffffff;
  }
  .sf-menu ul {
    list-style: none;
    padding-left: 10px;
  }
  .sf-menu ul li a {
    display: block;
    padding: 10px;
    font-weight: 300;
    font-size: 16px;
    text-transform: none;
  }
  .sf-menu ul li a:before {
    content: "-";
    padding-right: 5px;
  }
  .sf-menu ul ul li a:before {
    content: "--";
    padding-right: 5px;
  }
  /*** arrows (for all except IE7) **/
  .sf-arrows .sf-with-ul {
    padding-right: 2.5em;
    *padding-right: 1em;
    /* no CSS arrows for IE7 (lack pseudo-elements) */
  }
  /* styling for both css and generated arrows */
  .sf-arrows .sf-with-ul:after {
    font-family: "rt-icons-2";
    content: "\e7c3";
    position: absolute;
    right: 3em;
    height: 0;
    width: 0;
    font-size: 20px;
  }
  /*top menu level*/
  .sf-arrows .sfHover > a,
  .sf-arrows .sfHover > a:hover {
    color: #ffffff;
    background-color: #323232;
  }
}

.mainmenu_wrapper {
  margin: 0 -15px;
}

.sf-menu {
  font-size: 0px;
  color: #666565;
}

.sf-menu a {
  font-size: 16px;
  font-weight: 500;
}

/*
    Menu layout for (min-width: 992px)
    */
@media (min-width: 992px) {
  .sf-menu > li {
    width: 12.5%;
    text-align: center;
    z-index: 1;
  }
  /*
        Top color lines
        */
  .sf-menu > li:before {
    content: "";
    display: inline-block;
    width: calc(100% + 1px);
    z-index: 2;
  }

  .sf-menu > li:last-of-type:before {
    width: 100%;
  }

  .sf-menu > li:nth-of-type(1):before {
    border-top: 5px solid #f5abcb;
  }

  .sf-menu > li:nth-of-type(2):before {
    border-top: 5px solid #9cc0e5;
  }

  .sf-menu > li:nth-of-type(3):before {
    border-top: 5px solid #c2dc71;
  }

  .sf-menu > li:nth-of-type(4):before {
    border-top: 5px solid #9ed7c8;
  }

  .sf-menu > li:nth-of-type(5):before {
    border-top: 5px solid #f5abcb;
  }

  .sf-menu > li:nth-of-type(6):before {
    border-top: 5px solid #9cc0e5;
  }

  .sf-menu > li:nth-of-type(7):before {
    border-top: 5px solid #c2dc71;
  }

  .sf-menu > li:nth-of-type(8):before {
    border-top: 5px solid #9ed7c8;
  }
  /*
        Divider
        */
  .sf-menu > li:not(:last-of-type) {
    border-right: 1px solid #eaebe2;
  }
  /*
        Submenus
        */
  .sf-menu ul li {
    padding: 12px 0;
  }

  .sf-menu ul {
    width: calc(100% + 1px);
  }

  .sf-menu ul ul {
    background-color: #666565;
  }

  .sf-menu ul ul a {
    color: #fff;
  }
  /*
        Hover color
        */
  .sf-menu > li:nth-of-type(1) > a:hover,
  .sf-menu > li:nth-of-type(1).active > a,
  .sf-menu > li:nth-of-type(1) ul li.active a,
  .sf-menu > li:nth-of-type(1) ul a:hover {
    color: #f5abcb;
  }

  .sf-menu > li:nth-of-type(2) > a:hover,
  .sf-menu > li:nth-of-type(2).active > a,
  .sf-menu > li:nth-of-type(2) ul a:hover,
  .sf-menu > li:nth-of-type(2) ul li.active a {
    color: #9cc0e5;
  }

  .sf-menu > li:nth-of-type(3) > a:hover,
  .sf-menu > li:nth-of-type(3).active > a,
  .sf-menu > li:nth-of-type(3) ul a:hover,
  .sf-menu > li:nth-of-type(3) ul li.active a {
    color: #c2dc71;
  }

  .sf-menu > li:nth-of-type(4) > a:hover,
  .sf-menu > li:nth-of-type(4).active > a,
  .sf-menu > li:nth-of-type(4) ul a:hover,
  .sf-menu > li:nth-of-type(4) ul li.active a {
    color: #9ed7c8;
  }

  .sf-menu > li:nth-of-type(5) > a:hover,
  .sf-menu > li:nth-of-type(5).active > a,
  .sf-menu > li:nth-of-type(5) ul a:hover,
  .sf-menu > li:nth-of-type(5) ul li.active a {
    color: #f5abcb;
  }

  .sf-menu > li:nth-of-type(6) > a:hover,
  .sf-menu > li:nth-of-type(6).active > a,
  .sf-menu > li:nth-of-type(6) ul a:hover,
  .sf-menu > li:nth-of-type(6) ul li.active a {
    color: #9cc0e5;
  }

  .sf-menu > li:nth-of-type(7) > a:hover,
  .sf-menu > li:nth-of-type(7).active > a,
  .sf-menu > li:nth-of-type(7) ul a:hover,
  .sf-menu > li:nth-of-type(7) ul li.active a {
    color: #c2dc71;
  }

  .sf-menu > li:nth-of-type(8) > a:hover,
  .sf-menu > li:nth-of-type(8).active > a,
  .sf-menu > li:nth-of-type(8) ul a:hover,
  .sf-menu > li:nth-of-type(8) ul li.active a {
    color: #9ed7c8;
  }

  /*
        Text overflow for submenu
        */
  .sf-menu li ul a {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/*
    Layout for mobile menu
    */
@media (max-width: 991px) {
  .toggle_menu-wrap {
    position: relative;
    right: 15px;
    height: 60px;
    width: calc(100% + 30px);
    background-color: #f0f1eb;
  }
}

.page_header.mobile-active .toggle_menu-wrap {
  position: fixed;
  height: 60px;
  width: 60px;
  top: 0;
  right: 0;
  z-index: 3000;
}

@media (max-width: 767px) {
  .toggle_menu-wrap {
    z-index: 3000;
  }

  .toggle_menu-wrap.affix {
    position: fixed;
    height: 60px;
    top: 0;
    right: 0;
    width: 100%;
    background-color: #f0f1eb;
  }
}
/*
    End of Layout for mobile menu
    */

/*isotope*/

/* item media links */
.item-media {
  position: relative;
  overflow: hidden;
}

.media-links {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.media-links div {
  position: absolute;
  top: 26%;
  width: 100%;
  /*margin-top: -25px;*/
  z-index: 4;
}

.media-links:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(102, 101, 101, 0.9);
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

[class*="item"]:hover .media-links:before {
  filter: alpha(opacity=90);
  opacity: 0.9;
}

.media-links a.abs-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

[class*="item"]:hover .media-links a.abs-link {
  opacity: 1;
}

.media-links a.abs-link:before {
  content: "";
  position: absolute;
  top: 30px;
  left: 30px;
  bottom: 30px;
  right: 30px;
  border: 1px solid #ffffff;
}

.links-wrap a:not(:last-of-type) {
  position: relative;
  width: 60px;
  height: 60px;
  line-height: 60px;
  top: -100px;
  display: inline-block;
  z-index: 5;
  font-size: 24px;
  text-align: center;
  filter: alpha(opacity=0);
  opacity: 0;
  transition: all 0.6s;
  -moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  border: 2px solid #fff;
  color: #ffffff;
}

.links-wrap a:not(:last-of-type):hover {
  color: #818181;
  background-color: #fff;
}

.links-wrap a.p-link {
  background-color: transparent;
  border-color: #ffffff;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  -moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  -ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  -o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  -webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
}

.links-wrap a:not(:last-of-type):before {
  font-family: "rt-icons-2";
  content: "\e6d4";
}

.links-wrap a.p-link:before {
  content: "\e732";
}

[class*="item"]:hover .links-wrap a {
  top: 0px;
  filter: alpha(opacity=100);
  opacity: 1;
}

[class*="item"]:hover .media-links div {
  filter: alpha(opacity=100);
  opacity: 1;
}
.isotope_container {
  -webkit-transition: height 0.2s ease-in-out 0s;
  -moz-transition: height 0.2s ease-in-out 0s;
  -o-transition: height 0.2s ease-in-out 0s;
  -ms-transition: height 0.2s ease-in-out 0s;
  transition: height 0.2s ease-in-out 0s;
}

/*
**plugins
*/
.owl-carousel {
  position: relative;
  margin-bottom: 50px;
}

.owl-carousel:hover .owl-nav {
  top: 50%;
  opacity: 1;
}

.owl-carousel .owl-nav {
  position: absolute;
  top: -5%;
  opacity: 0;
  margin-top: -30px;
  right: 0;
  left: 0;
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  -ms-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
}

.owl-carousel .owl-nav > div {
  color: transparent;
  font-size: 0;
  position: absolute;
  right: 10px;
  text-align: center;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
}

.owl-carousel .owl-nav > div:first-child {
  left: 10px;
  right: auto;
}

.owl-carousel .owl-nav > div:after {
  font-family: "rt-icons-2";
  font-size: 20px;
  line-height: 60px;
  content: "\e7c5";
  color: #323232;
}

.owl-carousel .owl-nav > div:first-child:after {
  content: "\e7c4";
}

.owl-carousel .owl-nav > div:hover:after {
  color: #f5abcb;
}

.owl-carousel.owl-center .owl-nav {
  top: 0;
  bottom: 0;
  position: static;
  margin-top: 0;
}

.owl-carousel.owl-center .owl-nav > div {
  right: -15px;
  width: 50px;
  top: 0;
  bottom: 0;
  background-color: rgba(50, 50, 50, 0.6);
}

.owl-carousel.owl-center .owl-nav > div:first-child {
  left: -15px;
  right: auto;
}

.owl-carousel.owl-center .owl-nav > div:first-child:after {
  left: 16px;
}

.owl-carousel.owl-center .owl-nav > div:after {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  color: #ffffff;
  font-size: 20px;
  left: 21px;
}

/*
 * Owl Carousel CSS File
 *  v2.0
 */
/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 *  Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/*
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

/*
 *  Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-dots {
  text-align: center;
  margin-top: 40px;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 6px;
  border: 2px solid #323232;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  border-color: #f5abcb;
}

.owl-custom-nav {
  text-align: center;
}

.owl-custom-nav [class*="custom"] {
  cursor: pointer;
  position: relative;
  bottom: 2px;
}

.owl-custom-nav > div {
  display: inline-block;
}

/* owl images fix */
.owl-carousel .owl-item {
  -webkit-transition: opacity 0.4s ease 0.2s;
  -moz-transition: opacity 0.4s ease 0.2s;
  -o-transition: opacity 0.4s ease 0.2s;
  -ms-transition: opacity 0.4s ease 0.2s;
  transition: opacity 0.4s ease 0.2s;
  opacity: 0;
}

.owl-carousel .owl-item.active {
  opacity: 1;
}

.owl-center .owl-item {
  opacity: 1;
}

/*
flexslider
*/
/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */
/* Browser Resets
*********************************/
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* FlexSlider Necessary Styles
*********************************/
.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
  width: 100%;
  display: block;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block;
}

/* FlexSlider Default Theme
*********************************/
.flexslider {
  margin: 0 0;
  position: relative;
  zoom: 1;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.flexslider .slides {
  zoom: 1;
}

/* Direction Nav */
.flex-direction-nav {
  *height: 0;
}

.intro_section .flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 70px;
  height: 100%;
  /*margin: -20px 0 0;*/
  position: absolute;
  /*top: 50%;*/
  z-index: 10;
  overflow: hidden;
  opacity: 1;
  visibility: visible;
  cursor: pointer;
  font-size: 0;
  line-height: 350px;
  color: transparent;
  text-align: center;
}

@media (max-width: 600px) {
  .intro_section .flex-direction-nav a {
    top: 0;
  }
}

.intro_section .flex-direction-nav .flex-prev {
  /*left: 40px;*/
  background-color: #c2dc71;
}

.intro_section .flex-direction-nav .flex-next {
  right: 0;
  background-color: #9ed7c8;
  /*text-align: right;*/
}

.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover {
  opacity: 1;
}

.flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default;
}

.intro_section .flex-direction-nav a:before {
  font-family: "rt-icons-2";
  font-size: 20px;
  content: "\f104";
  color: #ffffff;
  /*position: absolute;*/
  /*top: 5px;*/
  /*left: 12px;*/
}

.intro_section .flex-direction-nav a.flex-next:before {
  content: "\f105";
  left: 14px;
}

/* Pause/Play */
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}

.flex-pauseplay a:before {
  font-family: "rt-icons-2";
  font-size: 20px;
  display: inline-block;
  content: "\e6a2";
}

.flex-pauseplay a:hover {
  opacity: 1;
}

.flex-pauseplay a.flex-play:before {
  content: "\e711";
}

/* Control Nav */
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: 42px;
  text-align: center;
  z-index: 10;
}

.flex-control-nav li {
  text-align: center;
  display: inline-block;
  padding: 0 4px;
  zoom: 1;
  *display: inline;
}

.flex-control-paging li a {
  width: 12px;
  height: 12px;
  display: block;
  color: transparent;
  border: 2px solid #323232;
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.ds .flex-control-paging li a,
.cs .flex-control-paging li a {
  border-color: #ffffff;
}

.flex-control-paging li a:hover {
  border-color: #f5abcb;
}

.flex-control-paging li a.flex-active {
  border-color: #f5abcb;
}

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}

.flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: 0.7;
  cursor: pointer;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}

@media screen and (max-width: 600px) {
  .flex-direction-nav .flex-prev {
    top: 14%;
  }
  .flex-direction-nav .flex-next {
    top: 14%;
  }
}

/* custom nav for flex slider*/
.flex-custom-nav {
  text-align: center;
  position: absolute;
  bottom: 38px;
  right: 0;
  left: 0;
  z-index: 3;
  line-height: 1;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

.flex-custom-nav .flex-control-nav {
  width: auto;
  display: inline-block;
  position: static;
}

.flex-custom-nav .flex-custom-link {
  font-size: 12px;
  position: relative;
  bottom: 7px;
  margin: 0 8px;
  color: #323232;
}

.ds .flex-custom-nav .flex-custom-link,
.cs .flex-custom-nav .flex-custom-link {
  color: #ffffff;
}

.flex-custom-nav .flex-custom-link:hover {
  color: #f5abcb;
}

.intro_section:hover .flex-custom-nav {
  opacity: 1;
}

/*
prettyPhoto
*/
div.facebook .pp_expand {
  background: url(../img/prettyPhoto/facebook/sprite.png) -31px -26px no-repeat;
  cursor: pointer;
}

div.facebook .pp_expand:hover {
  background: url(../img/prettyPhoto/facebook/sprite.png) -31px -47px no-repeat;
  cursor: pointer;
}

div.facebook .pp_contract {
  background: url(../img/prettyPhoto/facebook/sprite.png) 0 -26px no-repeat;
  cursor: pointer;
}

div.facebook .pp_contract:hover {
  background: url(../img/prettyPhoto/facebook/sprite.png) 0 -47px no-repeat;
  cursor: pointer;
}

div.facebook .pp_close {
  width: 22px;
  height: 22px;
  background: url(../img/prettyPhoto/facebook/sprite.png) -1px -1px no-repeat;
  cursor: pointer;
  z-index: 3000;
}

div.facebook .pp_description {
  margin: 0 37px 0 0;
}

div.facebook .pp_loaderIcon {
  background: url(../img/prettyPhoto/facebook/loader.gif) center center
    no-repeat;
}

div.facebook .pp_arrow_previous {
  background: url(../img/prettyPhoto/facebook/sprite.png) 0 -71px no-repeat;
  height: 22px;
  margin-top: 0;
  width: 22px;
}

div.facebook .pp_arrow_previous.disabled {
  background-position: 0 -96px;
  cursor: default;
}

div.facebook .pp_arrow_next {
  background: url(../img/prettyPhoto/facebook/sprite.png) -32px -71px no-repeat;
  height: 22px;
  margin-top: 0;
  width: 22px;
}

div.facebook .pp_arrow_next.disabled {
  background-position: -32px -96px;
  cursor: default;
}

div.facebook .pp_nav {
  margin-top: 0;
}

div.facebook .pp_nav p {
  font-size: 15px;
  padding: 0 3px 0 4px;
  margin-top: -2px;
}

div.facebook .pp_nav .pp_play {
  background: url(../img/prettyPhoto/facebook/sprite.png) -1px -123px no-repeat;
  height: 22px;
  width: 22px;
}

div.facebook .pp_nav .pp_pause {
  background: url(../img/prettyPhoto/facebook/sprite.png) -32px -123px no-repeat;
  height: 22px;
  width: 22px;
}

div.facebook .pp_next:hover {
  background: url(../img/prettyPhoto/facebook/btnNext.png) center right
    no-repeat;
  cursor: pointer;
}

div.facebook .pp_previous:hover {
  background: url(../img/prettyPhoto/facebook/btnPrevious.png) center left
    no-repeat;
  cursor: pointer;
}

div.pp_pic_holder a:focus {
  outline: none;
}

div.pp_overlay {
  background: #000;
  display: none;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9500;
}

div.pp_pic_holder {
  display: none;
  position: absolute;
  width: 100px;
  z-index: 10000;
}

.pp_content {
  height: 40px;
  min-width: 40px;
  overflow: hidden;
}

* html .pp_content {
  width: 40px;
}

.pp_content_container {
  position: relative;
  text-align: left;
  width: 100%;
}

.pp_content_container .pp_left {
  padding-left: 20px;
}

.pp_content_container .pp_right {
  padding-right: 20px;
}

.pp_content_container .pp_details {
  float: left;
  /*margin:10px 0 2px;*/
  padding: 7px 10px;
  background-color: #ffffff;
}

.pp_description {
  display: none;
  margin: 0;
}

.pp_social {
  float: left;
  margin: 0;
}

.pp_social .facebook {
  float: left;
  margin-left: 5px;
  width: auto;
  overflow: hidden;
}

.pp_social .twitter {
  float: left;
}

.pp_nav {
  clear: right;
  float: left;
  margin: 3px 10px 0 0;
}

.pp_nav p {
  float: left;
  white-space: nowrap;
  margin: 2px 4px;
}

.pp_nav .pp_play,
.pp_nav .pp_pause {
  float: left;
  margin-right: 4px;
  text-indent: -10000px;
}

a.pp_arrow_previous,
a.pp_arrow_next {
  display: block;
  float: left;
  height: 15px;
  margin-top: 3px;
  overflow: hidden;
  text-indent: -10000px;
  width: 14px;
}

.pp_hoverContainer {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2000;
}

.pp_gallery {
  display: none;
  left: 50%;
  margin-top: -50px;
  position: absolute;
  z-index: 10000;
}

.pp_gallery div {
  float: left;
  overflow: hidden;
  position: relative;
}

.pp_gallery ul {
  float: left;
  height: 35px;
  position: relative;
  white-space: nowrap;
  margin: 0 0 0 5px;
  padding: 0;
}

.pp_gallery ul a {
  border: 1px rgba(0, 0, 0, 0.5) solid;
  display: block;
  float: left;
  height: 33px;
  overflow: hidden;
}

.pp_gallery ul a img {
  border: 0;
}

.pp_gallery li {
  display: block;
  float: left;
  margin: 0 5px 0 0;
  padding: 0;
}

.pp_gallery li.default a {
  background: url(../img/prettyPhoto/facebook/default_thumbnail.gif) 0 0
    no-repeat;
  display: block;
  height: 33px;
  width: 50px;
}

.pp_gallery .pp_arrow_previous,
.pp_gallery .pp_arrow_next {
  margin-top: 7px !important;
}

a.pp_next {
  background: url(../img/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px
    no-repeat;
  display: block;
  float: right;
  height: 100%;
  text-indent: -10000px;
  width: 49%;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}

a.pp_previous {
  background: url(../img/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px
    no-repeat;
  display: block;
  float: left;
  height: 100%;
  text-indent: -10000px;
  width: 49%;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}

a.pp_expand,
a.pp_contract {
  cursor: pointer;
  display: none;
  height: 20px;
  position: absolute;
  right: 30px;
  text-indent: -10000px;
  top: 10px;
  width: 20px;
  z-index: 20000;
}

a.pp_close {
  position: absolute;
  right: 6px;
  top: -16px;
  display: block;
  line-height: 22px;
  text-indent: -10000px;
}

.pp_loaderIcon {
  display: block;
  height: 24px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 24px;
  margin: -12px 0 0 -12px;
}

#pp_full_res {
  line-height: 1 !important;
}

#pp_full_res .pp_inline {
  text-align: left;
}

#pp_full_res .pp_inline p {
  margin: 0 0 15px;
}

div.ppt {
  color: #ffffff;
  display: none;
  font-size: 17px;
  z-index: 9999;
  margin: 0 0 5px 15px;
}

.pp_top,
.pp_bottom {
  height: 20px;
  position: relative;
}

* html .pp_top,
* html .pp_bottom {
  padding: 0 20px;
}

.pp_top .pp_left,
.pp_bottom .pp_left {
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px;
}

.pp_top .pp_middle,
.pp_bottom .pp_middle {
  height: 20px;
  left: 20px;
  position: absolute;
  right: 20px;
}

* html .pp_top .pp_middle,
* html .pp_bottom .pp_middle {
  left: 0;
  position: static;
}

.pp_top .pp_right,
.pp_bottom .pp_right {
  height: 20px;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.pp_fade,
.pp_gallery li.default a img {
  display: none;
}

/*
easy pie chart
*/
.chart {
  position: relative;
  display: inline-block;
  width: 270px;
  height: 270px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.percent {
  display: inline-block;
  padding-top: 80px;
  font-size: 50px;
  font-weight: 100;
  z-index: 2;
  letter-spacing: -0.05em;
}

.percent:after {
  content: "%";
}

.angular {
  margin-top: 100px;
}

.angular .chart {
  margin-top: 0;
}

/*
timetable
*/
#timetable span {
  display: block;
}

#timetable th,
#timetable td {
  vertical-align: middle;
  font-weight: normal;
  text-align: center;
  padding: 25px 5px;
}

#timetable thead {
  color: #ffffff;
}

#timetable thead th {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-width: 0 1px;
}

#timetable tbody th,
#timetable tbody td {
  border: 1px solid #e1e1e1;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

#timetable tbody .current {
  background-color: #f5abcb;
  border-color: #f5abcb;
}

#timetable tbody .current a {
  color: #ffffff;
}

/*
totop
*/
#toTop {
  bottom: 0;
  right: 0;
  display: none;
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: fixed;
  text-decoration: none;
  z-index: 9999;
  font-size: 0;
  color: transparent;
  background-color: #f5abcb;
  opacity: 0.7;
}

#toTop:after {
  color: #ffffff;
  content: "\f106";
  font-size: 16px;
  line-height: 50px;
  font-family: "rt-icons-2";
  text-align: center;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#toTopHover,
#toTop:hover {
  bottom: 3px;
  opacity: 1;
}

#toTop:hover:after {
  color: #ffffff;
}

#toTop:active,
#toTop:focus {
  outline: medium none;
}

.modal-open #toTop {
  display: none !important;
}

/* jQuery Countdown styles 2.0.2. */
#comingsoon-countdown {
  text-align: center;
}

#comingsoon-countdown:before,
#comingsoon-countdown:after {
  clear: both;
  display: table;
  content: " ";
}

.countdown-rtl {
  direction: rtl;
}

.countdown-row {
  clear: both;
  display: table;
  margin: auto;
  padding: 20px 0;
  margin-bottom: 20px;
  text-align: center;
}

.countdown-section {
  display: table-cell;
  padding: 10px 30px 20px;
  text-align: center;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.countdown-section:first-child {
  border-left-width: 0;
}

.countdown-amount {
  font-size: 90px;
  line-height: 1;
  font-weight: 100;
  color: #f5abcb;
}

.countdown-period {
  display: block;
  font-size: 28px;
  font-weight: 100;
}

.countdown-descr {
  display: block;
}

@media (max-width: 767px) {
  .countdown-section {
    padding: 20px 8px 0;
  }
  .countdown-amount {
    font-size: 40px;
  }
  .countdown-period {
    font-size: 16px;
  }
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  *:first-line {
    background: transparent !important;
    color: #000 !important; /* Black prints faster:
                                   http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

/* ==========
   Breadcrumbs
   ========== */
.page_breadcrumbs {
  /*padding-top: 25px;*/
  background-color: #666565;
}

.page_breadcrumbs h3 {
  margin-top: 25px;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}

.breadcrumb {
  background-color: transparent;
}

.breadcrumb > li + li:before {
  color: #989898;
}

.breadcrumb a {
  color: #f5abcb;
  font-weight: 500;
}

.breadcrumb a:hover {
  color: #fff;
}

.breadcrumb li:first-of-type {
  margin-right: 5px;
}

.breadcrumb li:last-of-type {
  margin-left: 5px;
}

.breadcrumb li.active a {
  color: #fff;
  font-weight: 500;
}
/* ==========
   End of Breadcrumbs
   ========== */

/* ====================================
   Home Page
   ==================================== */

/* ==========
   Skills
   ========== */
.skills-cont {
  background-color: #f0f1eb;
}

.skill {
  position: relative;
  max-width: 370px;
  margin: auto;
  color: #666565;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  z-index: 2;
}

@media (max-width: 992px) {
  .skills-cont div.col-md-4:not(:last-of-type) .skill {
    margin-bottom: 201px;
  }
}

.skill-info {
  margin-top: 1px;
}

.skill-1 .skill-info {
  background-color: #f5abcb;
}
.skill-2 .skill-info {
  background-color: #9cc0e5;
}
.skill-3 .skill-info {
  background-color: #c2dc71;
}

.skill-1 .skill-head {
  background: #f5abcb url("../images/skill-1.png") no-repeat center;
}
.skill-2 .skill-head {
  background: #9cc0e5 url("../images/skill-2.png") no-repeat center;
}
.skill-3 .skill-head {
  background: #c2dc71 url("../images/skill-3.png") no-repeat center;
}

.skill .skill-head {
  position: absolute;
  top: -121px;
  height: 120px;
  width: 100%;
  padding: 33px 0;
  font-size: 30px;
  letter-spacing: -1px;
  line-height: 30px;
}

.skill-head strong {
  color: #fff;
}

.skill-info ul {
  list-style: none;
  height: 250px;
  margin: 0;
  padding: 31px 0;
}

.skill-info ul li {
  max-width: 250px;
  margin: auto;
  padding: 20px 0;
}

.skill-info ul li:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.skill-detail {
  position: absolute;
  bottom: -60px;
  left: 0;
  /*display: block;*/
  height: 60px;
  width: 100%;
  line-height: 60px;
  font-size: 16px;
  background-color: #666565;
  font-weight: 500;
}

.skill-1 .skill-detail {
  color: #f5abcb;
}
.skill-2 .skill-detail {
  color: #9cc0e5;
}
.skill-3 .skill-detail {
  color: #c2dc71;
}

.skill-1 .skill-detail:hover,
.skill-2 .skill-detail:hover,
.skill-3 .skill-detail:hover {
  color: #fff;
}
/* ==========
   End of Skills
   ========== */

/* ==========
   Welcome section
   ========== */
.welcome {
  margin-top: 170px;
  margin-bottom: 120px;
}

.welcome .poppins {
  letter-spacing: normal;
}

.about-site {
  max-width: 770px;
  margin: 45px auto 25px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  color: #666565;
}

.welcome .btn-type1 {
  color: #666565;
  border-color: #666565;
}

.welcome .btn-type1:hover {
  background-color: #666565;
}
/* ==========
   End of Welcome section
   ========== */

/* ==========
   Team slider
   ========== */
.team .slide-container {
  position: relative;
}

.team .slide-container:before {
  content: "";
  position: absolute;
  top: 19px;
  left: 19px;
  width: calc(100% - 38px);
  height: calc(100% - 38px);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  z-index: -1;
}

.team .slide-container:after {
  content: "";
  position: absolute;
  top: 19px;
  left: 19px;
  width: calc(100% - 38px);
  height: calc(100% - 38px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.team .slides li:nth-of-type(1) .slide-container:before {
  background-color: #c2dc71;
  outline: 19px solid #c2dc71;
}
.team .slides li:nth-of-type(2) .slide-container:before {
  background-color: #9ed7c8;
  outline: 19px solid #9ed7c8;
}
.team .slides li:nth-of-type(3) .slide-container:before {
  background-color: #f5abcb;
  outline: 19px solid #f5abcb;
}

.team .slide_description_wrapper {
  text-align: right;
}

.team .slide_description {
  width: 50%;
  text-align: left;
  font-size: 16px;
  color: #666565;
}

.team .slide_description .poppins,
.team .slide_description .btn-type1 {
  color: #fff;
  border-color: #fff;
}

.team .slide_description .btn-type1:focus {
  color: #666565;
  background-color: #fff;
}

.team .slide_description .poppins {
  line-height: 60px;
}

.boxed .team .slide_description .poppins {
  font-size: 30px;
  line-height: 40px;
}

.team .slide_description .btn-type1:hover {
  color: #666565;
  background-color: #fff;
}

.team .slide_description .grand-hotel {
  color: #666565;
}

.team .nanny-descr {
  margin: 45px 0;
  line-height: 30px;
}

.boxed .team .nanny-descr {
  margin: 10px 0;
}

.team .btn-type1:active {
  background-color: #fff;
}

@media (min-width: 1450px) {
  .team .nav-container {
    width: 1400px;
  }
}

@media (min-width: 1600px) {
  .team .nav-container {
    width: 1580px;
  }
}

.team .flex-control-nav {
  width: 12px;
  height: 60px;
  position: absolute;
  /*vertical-align: middle;*/
  right: 0;
  top: calc(50% - 30px);
  z-index: 10;
}

.boxed .team .flex-control-nav {
  right: 60px;
}

@media (min-width: 992px) and (max-width: 1400px) {
  .team .slide_description .poppins {
    font-size: 30px;
    line-height: 40px;
  }

  .team .nanny-descr {
    margin: 10px 0;
    line-height: 25px;
  }

  .team .slide_description {
    position: relative;
    left: -5%;
    width: 45%;
  }
}

.team .flex-control-nav li {
  text-align: center;
  display: block;
  padding: 4px 0;
}

.team .flex-control-nav li a {
  border-color: #fff;
}

.team .flex-control-nav li a:hover,
.team .flex-control-nav li a.flex-active {
  background-color: #fff;
}

@media (max-width: 991px) {
  .team .slide_description_wrapper {
    position: relative;
  }

  .team .slide_description {
    width: 100%;
  }

  .team .slide_description h3.poppins {
    color: #9cc0e5;
  }

  .team .slide_description .btn-type1 {
    color: #666565;
    border-color: #666565;
  }

  .team .flex-control-nav li a {
    border-color: #666565;
  }

  .team .flex-control-nav li a:hover,
  .team .flex-control-nav li a.flex-active {
    background-color: #666565;
  }

  .team .slide_description a.btn-type1:hover,
  .team .slide_description a.btn-type1:focus,
  .team .slide_description a.btn-type1:active {
    color: #fff;
    background-color: #666565;
  }

  .team .flex-control-nav {
    right: 30px;
  }
}

@media (max-width: 480px) {
  .team .slide_description {
    padding: 0;
  }

  .team .slide_description_wrapper {
    padding: 20px 0 0;
  }

  .team .slide_description h3.poppins {
    font-size: 40px;
  }
}
/* ==========
   End of Team slider
   ========== */

/* ==========
   Info section
   ========== */
.info {
  padding: 120px 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  color: #666565;
  overflow: hidden;
}

.info ul {
  padding: 0;
  margin: 30px 0 0;
  list-style: none;
}

.info .poppins {
  letter-spacing: normal;
  margin: 15px 0;
}

.info ul li:not(:last-of-type) {
  margin-bottom: 15px;
}

.info ul li span {
  display: inline-block;
  vertical-align: text-top;
}

.step-icon {
  width: 61px;
  float: left;
}

.step-icon i {
  font-size: 48px;
}

.step-descr {
  float: left;
  width: calc(100% - 66px);
  margin-top: 5px;
}

.step-descr h4 {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
}

.for-nannies .step-icon {
  color: #9cc0e5;
}

.for-nannies .step-descr h4 {
  color: #f5abcb;
}

.for-parents .step-icon {
  color: #c2dc71;
}

.for-parents .step-descr h4 {
  color: #9ed7c8;
}

.for-parents .poppins {
  color: #c2dc71;
}

.for-parents .poppins span {
  color: #9ed7c8;
  font-weight: 700;
}
/* ==========
   End of Info section
   ========== */

/* ==========
   Gallery section
   ========== */
.gallery {
  background-color: #f0f1eb;
  margin-bottom: 120px;
}

.gallery .col-sm-12 {
  padding: 0;
}

/*
Dots
*/
.owl-dots {
  margin: 50px auto;
  text-align: center;
}

.owl-dot {
  display: inline-block;
  margin: 0 5px;
  vertical-align: middle;
}

.owl-dot span {
  display: block;
  width: 12px;
  height: 12px;
  border: 2px solid #666565;
  /*background-color: #666565;*/
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.owl-dot.active span,
.owl-dot span:hover {
  background-color: #666565;
}

/*
galery-navigation
*/
.gallery .container {
  position: relative;
}

.gallery .poppins {
  margin: 35px auto;
  letter-spacing: -4px;
  text-align: center;
}

.gallery .poppins span {
  font-weight: 700;
}

.light-green {
  color: #c2dc71;
}
.turquoise {
  color: #9ed7c8;
}

.gallery-nav {
  /*margin-top: -80px;*/
  font-size: 0;
}

@media (min-width: 1199px) {
  .gallery-nav {
    position: absolute;
    top: 50px;
    width: 100%;
  }

  .boxed .gallery-nav {
    position: absolute;
    width: auto;
    top: 50px;
    left: 50px;
    right: 50px;
  }
}

@media (max-width: 1199px) {
  .gallery-nav > div {
    margin: 20px 0;
  }
}

.gallery-nav a {
  padding: 0 49px;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  color: #666565;
}

.boxed .gallery-nav a {
  padding: 0 20px;
}

.gallery-nav a:hover {
  color: #9cc0e5;
}

.gallery-nav a.active {
  color: #f5abcb;
}

.gallery-nav .col-md-6:last-of-type {
  text-align: right;
}

.gallery-nav .col-md-6:first-of-type a:first-of-type {
  padding-left: 0;
}
.gallery-nav .col-md-6:last-of-type a:last-of-type {
  padding-right: 0;
}

.gallery-nav .col-md-6 a:nth-of-type(2) {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}

@media (max-width: 991px) {
  .gallery-nav .col-md-6:first-of-type {
    padding-right: 0;
  }
  .gallery-nav .col-md-6:last-of-type {
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .gallery-nav .col-md-6,
  .gallery-nav .col-md-6:last-of-type {
    text-align: center;
  }

  .gallery-nav .col-md-6:first-of-type a:first-of-type {
    padding-left: 0;
  }
  .gallery-nav .col-md-6:last-of-type a:last-of-type {
    padding-right: 0;
  }
}

@media (max-width: 540px) {
  .gallery-nav .col-md-6 {
    width: 50%;
    float: left;
  }

  .gallery-nav .col-md-6 a {
    display: block;
    margin: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
  }

  .gallery-nav .col-md-6 a:nth-of-type(2) {
    border-right: none;
    border-left: none;
  }

  .gallery-nav .col-md-6:first-of-type a:first-of-type {
    padding-left: 0;
  }

  .gallery-nav .col-md-6:last-of-type a:last-of-type {
    padding-right: 0;
  }
}

/*
item media block
*/
.links-wrap h4 {
  position: relative;
  right: -100px;
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  margin: 25px 0 10px;
}

.links-wrap .read-about {
  position: relative;
  left: -100px;
  font-size: 16px;
  font-weight: 300;
  color: #f5abcb;
}

.links-wrap .read-about:hover {
  color: #fff;
}

.links-wrap h4,
.links-wrap .read-about {
  opacity: 0;
}

[class*="item"]:hover .links-wrap h4 {
  right: 0;
}

[class*="item"]:hover .links-wrap .read-about {
  left: 0;
}

[class*="item"]:hover .links-wrap .read-about,
[class*="item"]:hover .links-wrap h4 {
  filter: alpha(opacity=100);
  opacity: 1;
}

.links-wrap h4,
.links-wrap .read-about,
[class*="item"]:hover .links-wrap h4 {
  transition: all 0.6s;
  -moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

@media (max-width: 400px) {
  .links-wrap h4 {
    font-size: 20px;
    margin-top: 10px;
  }
}
/* ==========
   End of Gallery section
   ========== */

/* ==========
   Pricing section
   ========== */
.pricing {
  margin-bottom: 120px;
}

.price-1 {
  background-color: #f5abcb;
  border: 2px solid #f5abcb;
}
.price-2 {
  background-color: #9cc0e5;
  border: 2px solid #9cc0e5;
}
.price-3 {
  background-color: #c2dc71;
  border: 2px solid #c2dc71;
}

.price-1,
.price-2,
.price-3 {
  position: relative;
  margin: 40px auto 60px;
  max-width: 370px;
}

.price-1 .head {
  background: url(../images/plan-01.png) no-repeat center;
}
.price-2 .head {
  background: url(../images/plan-02.png) no-repeat center;
}
.price-3 .head {
  background: url(../images/plan-03.png) no-repeat center;
}

.pricing .head {
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -1px;
  color: #666565;
  padding: 30px 0;
}

.pricing .head strong {
  color: #fff;
}

.pricing .price {
  padding: 17px 0 13px;
  font-size: 20px;
  font-weight: 300;
  color: #666565;
  background-color: #fff;
}

.price-1 .poppins {
  color: #f5abcb;
}
.price-3 .poppins {
  color: #c2dc71;
}

.about-price {
  position: relative;
  height: 192px;
  width: 100%;
}

.about-price p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 260px;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  color: #fff;
}

.pricing .purchase {
  position: absolute;
  bottom: -60px;
  left: -2px;
  display: block;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  width: calc(100% + 4px);
  background-color: #666565;
  border: 2px solid #666565;
}

.price-1 .purchase {
  color: #f5abcb;
}
.price-2 .purchase {
  color: #9cc0e5;
}
.price-3 .purchase {
  color: #c2dc71;
}

.pricing .purchase:hover {
  color: #fff;
}
/* ==========
   End of Pricing section
   ========== */

/* ==========
   Testimonials section
   ========== */
.testimonials {
  position: relative;
  padding: 100px 0 120px;
  background-image: url(../images/testimonials-bg.jpg);
}

.testimonials:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(156, 192, 229, 0.95);
}

.testimonials i {
  font-size: 40px;
  color: #f5abcb;
}

.testimonials .carousel {
  margin-top: 25px;
}

.testimonials .carousel .media {
  margin-top: 100px;
}

.testimonials p {
  max-width: 770px;
  margin: auto;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  font-style: italic;
  color: #fff;
}

.testimonials h4 {
  margin-bottom: 0;
}

.testimonials h4 a {
  font-weight: 700;
  font-size: 16px;
  color: #666565;
}

.testimonials h4 a:hover {
  color: #fff;
}

.testimonials .media p {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

.testimonials .carousel-indicators {
  bottom: 50px;
  width: 100%;
  margin-left: -50%;
  z-index: 5;
}

.testimonials .carousel-indicators li {
  width: 60px;
  height: 60px;
  border: none;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  opacity: 0.3;
  background-size: cover;
}

.testimonials .carousel-indicators li:hover {
  opacity: 1;
}

.testimonials .carousel-indicators li.active {
  width: 70px;
  height: 70px;
  background-color: transparent;
  opacity: 1;
}

.testimonials .carousel-indicators li[data-slide-to="0"] {
  background-image: url(../images/author-1.jpg);
}
.testimonials .carousel-indicators li[data-slide-to="1"] {
  background-image: url(../images/author-2.jpg);
}
.testimonials .carousel-indicators li[data-slide-to="2"] {
  background-image: url(../images/author-3.jpg);
}
/* ==========
   End of Testimonials section
   ========== */

/* ==========
   Contacts section
   ========== */
#map {
  height: 500px;
}

.contacts .container {
  padding-top: 90px;
  padding-bottom: 100px;
}

.contacts .container span {
  /*display: block;*/
  font-size: 48px;
}

.contacts .container a,
.contacts .container p {
  display: block;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 300;
  color: #666565;
}

.contacts .container a:hover {
  color: #9cc0e5;
}

.contacts .container .col-md-4 {
  padding-top: 22px;
  padding-bottom: 15px;
}

@media (min-width: 992px) {
  .contacts .container .col-md-4:nth-of-type(2) {
    border-right: 1px solid #f0f1eb;
    border-left: 1px solid #f0f1eb;
  }
}
/*
Form
*/
.contacts .container-fluid {
  /*height: 730px;*/
  padding-top: 20px;
  padding-bottom: 100px;
  background: url(../images/wall_contact.jpg);
  opacity: 0.6;
}

.contacts .form-wrap {
  max-width: 570px;
  margin: 100px auto 0;
}

.contact-form {
  font-size: 0;
}

.contact-form span {
  font-size: 14px;
}

.contact-form input,
.contact-form select,
.contact-form textarea,
.widget_mailchimp input {
  padding: 12px 30px;
  height: 60px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  font-style: italic;
  color: #666565;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.contact-form select {
  position: relative;
  color: rgba(102, 101, 101, 0.6);
  font-style: italic;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

/*target Internet Explorer 9 and Internet Explorer 10:*/
@media screen and (min-width: 0\0) {
  select {
    background: none;
    padding: 5px;
  }
}

.contact-form select option {
  padding: 12px 45px;
  font-weight: 300;
  color: #666565;
}

.contact-form #email,
.contact-form #phone,
.contact-form .form-control-group,
.contact-form #time {
  width: calc(50% - 5px);
  display: inline-block;
}
.contact-form #time {
  float: right;
}

.contact-form #name,
.contact-form #email,
.contact-form #phone,
.contact-form .form-control-group,
.contact-form #time,
.contact-form textarea {
  margin-bottom: 10px;
}

.contact-form #email,
.contact-form .form-control-group {
  margin-right: 10px;
}

@media (max-width: 480px) {
  .contact-form #email,
  .contact-form #phone,
  .contact-form .form-control-group,
  .contact-form #time {
    display: block;
    width: 100%;
  }

  .contact-form #email,
  .contact-form .form-control-group {
    margin-right: 10px;
  }
}

.contact-form textarea {
  display: block;
  height: 180px;
  width: 100%;
  resize: none;
}

.contact-form button {
  display: block;
  margin: 60px auto 0;
  background: none;
}

.contact-form #time {
  cursor: pointer;
  background: #fff url(../images/time-bg.png) calc(100% - 25px) center no-repeat;
}

.contact-form .btn-type1:hover {
}

.form-control-group {
  position: relative;
}

.contact-form input:focus,
.contact-form select:focus,
.contact-form textarea:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  border-color: rgba(0, 0, 0, 0.1);
}

/*
Datepicker
*/
.form-control-group {
  position: relative;
}

.form-control-group .ui-datepicker-trigger {
  position: absolute;
  margin: 0;
  right: 20px;
  top: 0;
  bottom: 0;
  font-family: "rt-icons-2" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  color: #9cc0e5;
  border: none;
}

.form-control-group .ui-datepicker-trigger:hover {
  color: #818181;
}

.form-control-group .ui-datepicker-trigger:before {
  content: "\e751";
  font-size: 24px;
}

.ui-datepicker {
  display: none;
  width: 280px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ui-datepicker-header {
  height: 60px;
  padding: 17px 35px;
  text-align: center;
  background-color: #9cc0e5;
}

.ui-datepicker-prev {
  float: left;
}

.ui-datepicker-next {
  float: right;
}

.ui-datepicker-title,
.ui-datepicker-header a {
  font-size: 20px;
  font-weight: 300;
  color: #fff;
}

.ui-datepicker-header a {
  position: relative;
  top: 5px;
  font-family: "rt-icons-2" !important;
  speak: none;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-datepicker-prev:before {
  content: "\e7c4";
}

.ui-datepicker-next:before {
  content: "\e7c5";
}

.ui-datepicker-header a:hover {
  cursor: pointer;
  color: #666565;
}

.ui-datepicker-calendar {
  position: relative;
  margin: auto;
}

.ui-datepicker-calendar:before {
  content: "";
  position: absolute;
  display: block;
  left: -34px;
  right: 0;
  top: 0;
  height: 40px;
  width: 278px;
  background-color: #f5abcb;
  z-index: -1;
}

.ui-datepicker-calendar thead th {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  text-align: center;
  height: 40px;
}

.ui-datepicker-calendar tbody tr:first-of-type td {
  padding-top: 25px;
}
.ui-datepicker-calendar tbody tr:last-of-type td {
  padding-bottom: 25px;
}

.ui-datepicker-calendar tbody td {
  width: 30px;
  height: 30px;
  text-align: center;
}

.ui-datepicker-current-day {
  position: relative;
  background-color: #eaebe2;
}

.ui-datepicker-current-day:after {
  content: "";
  position: absolute;
  top: -3px;
  right: 0;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #f5abcb;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.ui-datepicker-calendar tbody td a {
  font-size: 16px;
  font-weight: 300;
  color: #666565;
}

.ui-datepicker-calendar tbody td.ui-datepicker-current-day a {
  color: #f5abcb;
}

.ui-datepicker-calendar tbody td:hover a {
  color: #9cc0e5;
}

.ui-datepicker-buttonpane {
  height: 70px;
  padding: 23px 35px;
  text-align: center;
  font-size: 16px;
  color: #f5abcb;
  background-color: #f0f1eb;
}

.ui-datepicker-other-month {
  cursor: pointer;
  color: #b2b2b2;
}

.ui-datepicker-buttonpane button:hover {
  color: #818181;
}

.ui-datepicker-buttonpane button {
  background: none;
  border: none;
}

.ui-datepicker-buttonpane button:active,
.ui-datepicker-buttonpane button:focus {
  outline: none;
}

.ui-datepicker-close {
  float: right;
}

.ui-datepicker-current {
  float: left;
}
/*
End of Datepicker
*/
/* ==========
   End of Contacts section
   ========== */

/* ====================================
   Home Page
   ==================================== */

/* ==========
   Footer
   ========== */
/*
Banner
*/
.page_footer {
  position: relative;
  padding: 60px 0 120px;
  background-color: #666565;
}

.banner-wrap {
  position: relative;
  margin: 60px 0 0;
  padding: 5px 95px;
  background-color: #f0f1eb;
}

footer .banner-wrap {
  background-color: #4c4c4c;
}

.banner-wrap a {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-7px);
  -moz-transform: translateY(-7px);
  -ms-transform: translateY(-7px);
  -o-transform: translateY(-7px);
  transform: translateY(-7px);
  font-size: 14px;
  font-family: "rt-icons-2" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #666566;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 10;
}

footer .banner-wrap a {
  color: #fff;
}

.banner-wrap a:hover {
  color: #f5abcb;
}

.banner-wrap a.flex-prev {
  left: 45px;
}
.banner-wrap a.flex-next {
  right: 45px;
}

.banner-wrap a.flex-prev:before {
  content: "\e7c4";
}
.banner-wrap a.flex-next:before {
  content: "\e7c5";
}

@media screen and (max-width: 600px) {
  .flex-direction-nav .flex-prev,
  .flex-direction-nav .flex-next {
    top: 50%;
  }
}
/*
End of Banner
*/

/*
Work Hours
*/
.open-hours {
  max-width: 350px;
  text-align: left;
}

.page_footer h3 {
  margin: 50px 0 30px;
  font-size: 30px;
  font-weight: 700;
  color: #9cc0e5;
}

.page_footer h3 span {
  color: #f5abcb;
  font-weight: 700;
}

.open-hours div {
  height: 60px;
  font-size: 16px;
  line-height: 60px;
  font-weight: 300;
  color: #fff;
  border-bottom: 1px solid rgba(234, 235, 226, 0.1);
}

.open-hours div:first-of-type {
  height: 61px;
  border-top: 1px solid rgba(234, 235, 226, 0.1);
}

.open-hours div span {
  float: right;
  color: #f5abcb;
}
/*
End of Work Hours
*/

/*
**widgets
*/
aside > div + div {
  margin-top: 10px;
}

aside > div + div > .widget-title {
  margin-top: 26px;
}

aside .widget + .widget {
  margin-top: 10px;
}

.widget li {
  position: relative;
}

.widget_recent_entries ul,
.widget_recent_comments ul,
.widget_archive ul,
.widget_categories ul,
.widget_meta ul,
.widget_nav_menu ul,
.widget_pages ul,
.widget_flickr ul,
.widget_twitter ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
widget_search
*/

.widget_mailchimp .form-inline {
  position: relative;
}

.widget_search .form-inline .form-group {
  display: block;
  vertical-align: top;
  position: relative;
}

.widget_search .form-inline input[type="text"] {
  padding-right: 50px;
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  .widget_search .form-group.inline-block {
    min-width: 370px;
    margin-right: 5px;
  }
}

.widget_search .form-inline .theme_button {
  font-size: 0;
  padding: 12px;
  margin: 0;
}

.widget_search .form-inline .theme_button {
  position: absolute;
  right: 0;
  top: 0;
  border: none !important;
  background-color: transparent !important;
  color: #9cc0e5 !important;
}

.widget_search .form-inline .theme_button:before {
  font-size: 16px;
  content: "\e656";
  font-family: "rt-icons-2";
}

.widget_search .form-inline .theme_button:hover {
  opacity: 0.5;
}

/*
widget_recent_entries
widget_recent_comments
widget_archive
widget_categories
widget_meta
widget_nav_menu
widget_pages
*/
.widget_recent_comments li {
  padding: 15px 0;
}

.widget_recent_comments li:first-child {
  padding-top: 0;
}

.widget_recent_comments li:before {
  font-family: "rt-icons-2";
  content: "\e65c";
  font-size: 16px;
  line-height: 1;
  position: relative;
  top: 4px;
  padding-right: 10px;
  color: #f5abcb;
}

.widget_recent_entries li {
  padding: 15px 0 15px 36px;
}

.widget_recent_entries p {
  margin-bottom: 0;
}

.widget_recent_entries li:first-child {
  padding-top: 0;
}

.widget_recent_entries li:before {
  font-family: "rt-icons-2";
  content: "\e65c";
  font-size: 16px;
  line-height: 1;
  position: absolute;
  top: 18px;
  left: 0;
  padding-right: 10px;
  color: #f5abcb;
}

.widget_recent_entries li:first-child:before {
  top: 5px;
}

.widget_recent_entries li + li,
.widget_recent_comments li + li,
.widget_archive li + li,
.widget_categories li + li,
.widget_meta li + li,
.widget_popular_entries li + li,
.widget_nav_menu li + li,
.widget_pages li + li {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.widget_recent_entries a,
.widget_recent_comments a,
.widget_archive a,
.widget_categories a,
.widget_meta a,
.widget_popular_entries a,
.widget_nav_menu a,
.widget_pages a,
.widget_twitter a {
  font-size: 14px;
  font-weight: 700;
  color: #666566;
}

.widget_recent_entries a:hover,
.widget_recent_comments a:hover,
.widget_archive a:hover,
.widget_categories a:hover,
.widget_meta a:hover,
.widget_popular_entries a:hover,
.widget_nav_menu a:hover,
.widget_pages a:hover,
.widget_twitter a:hover {
  opacity: 0.7;
}

.widget_archive a,
.widget_categories a,
.widget_meta a,
.widget_nav_menu a,
.widget_pages a {
  display: inline-block;
  padding: 12px 0 12px 24px;
  position: relative;
}

.widget_recent_entries li:first-child a,
.widget_archive li:first-child a,
.widget_categories li:first-child a,
.widget_meta li:first-child a,
.widget_nav_menu li:first-child a,
.widget_pages li:first-child a {
  padding-top: 0;
}

.widget_archive a:before,
.widget_categories a:before,
.widget_meta a:before,
.widget_nav_menu a:before,
.widget_pages a:before {
  font-family: "rt-icons-2";
  content: "\e7c1";

  color: #f5abcb;
  position: absolute;
  left: -3px;
}

.widget_archive span,
.widget_categories span {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -10px;
}

.widget_archive li:first-child span,
.widget_categories li:first-child span {
  margin-top: -14px;
}

.widget_nav_menu ul ul {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-left: 20px;
}

.widget_nav_menu ul ul li:first-child a {
  padding-top: 13px;
}

@media (min-width: 768px) {
  .page_copyright .widget_nav_menu li,
  .page_topline .widget_nav_menu li {
    display: inline-block;
    position: relative;
    border: none;
    padding-right: 5px;
  }
  .page_copyright .widget_nav_menu li:before,
  .page_topline .widget_nav_menu li:before {
    content: "";
    position: absolute;
    padding: 0;
    left: -5px;
    top: 5px;
    bottom: 5px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.05);
    opacity: 0.3;
    display: block;
  }
  .page_copyright .widget_nav_menu li:first-child:before,
  .page_topline .widget_nav_menu li:first-child:before {
    display: none;
  }
  .page_copyright .widget_nav_menu a,
  .page_topline .widget_nav_menu a {
    padding: 0 10px;
  }
  .page_copyright .widget_nav_menu a:before,
  .page_topline .widget_nav_menu a:before {
    display: none;
  }
}

/*widget_flickr*/
.widget_flickr li {
  display: inline-block;
  margin: 0 10px 10px 0;
}

.widget_flickr img {
  max-width: 70px;
  border-radius: 1px;
}

/*widget_popular_entries*/
.widget_popular_entries p {
  padding-top: 6px;
  margin-bottom: 10px;
}

.widget_popular_entries .media-body {
  overflow: visible;
}

.widget_popular_entries .media-left {
  padding-right: 20px;
}

.widget_popular_entries .media-object {
  max-width: 100px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .widget_popular_entries .media-object {
    max-width: 40px;
  }
}

.widget_popular_entries li {
  margin-top: 2px;
  padding: 20px 0 18px 0;
}

.widget_popular_entries li:first-child {
  padding-top: 0;
}

.widget_popular_entries li:last-child {
  padding-bottom: 0;
}

/*widget_banner*/
.widget_banner {
  overflow: hidden;
  line-height: 1;
}

.widget_banner .item-content {
  padding: 20px;
}

.widget_banner .item-content h3 {
  margin: 0;
  padding: 8px 0;
  font-size: 28px;
}

.widget_banner .item-content span {
  display: inline-block;
  text-transform: uppercase;
  padding: 4px 10px 1px;
  min-width: 120px;
}

.widget_banner .small-text {
  margin-bottom: 0;
}

/*widget_slider*/
.widget_slider .owl-carousel {
  margin-bottom: 0;
}

.widget_slider .owl-carousel:hover .owl-nav {
  top: -43px;
}

.widget_slider .owl-carousel .owl-nav {
  opacity: 1;
  top: -43px;
}

.widget_slider .owl-carousel .owl-nav > div {
  width: auto;
  height: auto;
  right: 0;
  background-color: transparent;
}

.widget_slider .owl-carousel .owl-nav > div:first-child {
  left: auto;
  right: 22px;
}

.widget_slider .owl-carousel .owl-nav > div:after {
  font-size: 12px;
  color: inherit;
  color: #1a1a1a;
}

/*widget_calendar*/
#calendar_wrap {
  width: 100%;
  margin-bottom: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (min-width: 768px) and (max-width: 992px) {
  #calendar_wrap {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.widget_calendar table {
  width: 100%;
  position: relative;
  text-align: center;
}

.widget_calendar caption {
  line-height: 1;
  font-size: 24px;
  padding: 18px 0 18px;
  text-align: center;
  color: #ffffff;
  background-color: #f5abcb;
}

.widget_calendar thead {
  background-color: #323232;
  border: 1px solid #323232;
  border-width: 7px 1px 7px 1px;
  color: #ffffff;
}

.widget_calendar thead th {
  font-weight: 300;
}

.widget_calendar thead th:first-child {
  padding-left: 30px;
}

.widget_calendar thead th:last-child {
  padding-right: 30px;
}

.widget_calendar tbody {
  font-weight: 300;
}

.widget_calendar tbody tr:first-child td {
  padding-top: 29px;
}

.widget_calendar tbody tr:last-child td {
  padding-bottom: 30px;
}

.widget_calendar tbody td {
  border: none;
  padding: 3px 4px;
  position: relative;
}

.widget_calendar tbody td:first-child {
  padding-left: 30px;
}

.widget_calendar tbody td:last-child {
  padding-right: 30px;
}

.widget_calendar tbody td a {
  font-weight: 700;
  color: #f5abcb;
}

.widget_calendar tbody td a:hover {
  color: #e23751;
}

.widget_calendar th {
  text-align: center;
}

.widget_calendar tfoot {
  position: absolute;
  top: -3px;
  right: 0;
  left: 0;
}

.widget_calendar tfoot td {
  position: absolute;
  top: 0;
}

.widget_calendar tfoot td a {
  color: #ffffff;
  text-align: center;
  font-size: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  display: inline-block;
}

.widget_calendar tfoot td a:hover {
  color: #ffffff;
  background-color: #f5abcb;
  border-color: #f5abcb;
}

.widget_calendar tfoot #prev {
  left: 0;
}

.widget_calendar tfoot #prev a {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.widget_calendar tfoot #prev a:before {
  font-size: 14px;
  font-family: "rt-icons-2";
  content: "\e7c4";
}

.widget_calendar tfoot #next {
  right: 0;
}

.widget_calendar tfoot #next a {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.widget_calendar tfoot #next a:after {
  font-size: 14px;
  font-family: "rt-icons-2";
  content: "\e7c5";
}

/*widget_twitter*/
.tweet_avatar {
  float: left;
}

.tweet_right {
  overflow: hidden;
  zoom: 1;
  margin-left: 60px;
}

.tweet_list li + li {
  margin-top: 10px;
  clear: both;
}

.tweet_list li + li .tweet_right {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.tweet_list li + li .tweet_right,
.tweet_list li + li .tweet_avatar {
  padding-top: 10px;
}

/*
Flickr
*/
.widget_flickr {
  max-width: 360px;
  padding: 0 5px;
  text-align: left;
}

.widget_flickr h3 {
  margin-left: 5px;
  color: #c2dc71;
}

.widget_flickr ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget_flickr ul li {
  position: relative;
  display: inline-block;
  margin: 5px;
}

.widget_flickr ul li:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.widget_flickr ul li:hover:before {
  background-color: rgba(102, 101, 101, 0.9);
}
/*
End of Flickr
*/

/*
Mailchimp
*/
.widget_mailchimp {
  max-width: 360px;
  padding: 0 5px;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  color: #fff;
  text-align: left;
}

.widget_mailchimp form {
  position: relative;
}

.widget_mailchimp .form-group {
  width: 100%;
}

.widget_mailchimp .mailchimp_email {
  width: 100%;
  color: rgba(255, 255, 255, 0.6);
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.1);
}

.widget_mailchimp .mailchimp_email:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.widget_mailchimp button {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 24px;
  color: #9ed7c8;
  background: none;
  border: none;
}

.widget_mailchimp p {
  margin-top: 30px;
  margin-bottom: 0;
}
/*
End of Mailchimp
*/
@media (max-width: 991px) {
  .open-hours,
  .widget_mailchimp,
  .widget_flickr {
    margin: auto;
  }
}

.page_footer .logo-footer {
  width: 160px;
  height: 160px;
  position: absolute;
  bottom: -45px;
  left: 50%;
  -webkit-transform: translateX(-91px);
  -moz-transform: translateX(-91px);
  -ms-transform: translateX(-91px);
  -o-transform: translateX(-91px);
  transform: translateX(-91px);
}
/* ==========
   End of Footer
   ========== */

/* ==========
   Copyright
   ========== */
.page_copyright {
  padding: 70px 0 50px;
  font-size: 16px;
  font-weight: 300;
  color: #666565;
}

.page_copyright a {
  color: #f5abcb;
}
.page_copyright a:hover {
  color: #9cc0e5;
}
/* ==========
   End of Copyright
   ========== */

/* ==========
   FAQ page
   ========== */
.faq-section {
  padding: 120px 0;
}

.panel-default {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: none;
}

.panel-default > .panel-heading {
  background-color: transparent;
  color: inherit;
  position: relative;
  border: none;
  border-radius: 0;
  padding: 0;
}

.panel-heading .panel-title {
  font-size: inherit;
  letter-spacing: 0;
  padding: 0;
  position: relative;
}

.panel-heading .panel-title > a {
  line-height: 1;
  display: block;
  padding: 22px 65px 22px 40px;
  font-weight: 500;
  color: #fff;
  background-color: #f5abcb;
  border: none;
  word-wrap: break-word;
}

.panel-heading .panel-title > a.collapsed {
  background-color: #f0f1eb;
  color: #666565;
}

.panel-heading .panel-title > a:after {
  color: #fff;
  content: "+";
  position: absolute;
  line-height: 58px;
  text-align: center;
  right: 0;
  top: 0;
  bottom: 0;
  width: 60px;
  letter-spacing: 0;
}

.panel-heading .panel-title > a.collapsed:after {
  content: "-";
  color: #666565;
}

.panel-heading .panel-title > a > i {
  position: relative;
  top: 1px;
  padding-right: 12px;
  color: #fff;
}

.panel-heading .panel-title > a.collapsed > i {
  color: #f5abcb;
}

.faq-section p {
  max-width: 750px;
  font-size: 20px;
  line-height: 24px;
  margin: 0 auto 60px;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top: none;
}

.panel-body {
  padding: 40px;
  line-height: 30px;
}
/* ==========
   End of FAQ page
   ========== */

/* ==========
   About page
   ========== */
.page_breadcrumbs + .testimonials {
  background-image: none;
}

.page_breadcrumbs + .testimonials:before {
  background: none;
}

.page_breadcrumbs + .testimonials p {
  color: #666565;
}

.hover-blue:hover {
  color: #9cc0e5 !important;
}
/* ==========
   End of About page
   ========== */

/* ==========
   Page_404
   ========== */
.page_404 {
  padding: 120px 0;
}

.page_404 .widget_search {
  margin-bottom: 20px;
  margin-top: 20px;
}

.not_found {
  font-size: 280px;
  font-weight: 700;
  line-height: 0.8;
}

@media (max-width: 600px) {
  .not_found {
    font-size: 100px;
  }
}

.not_found + h2 {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 8px;
  color: #666565;
  margin: 0 0 50px;
}

.not_found + h2 + p {
  color: #666565;
}

.page_404 .form-group label {
  display: none;
}

form.searchform {
  position: relative;
}

.page_404 .form-control {
  border-color: rgba(0, 0, 0, 0.1);
}

/* ==========
   End of Page_404
   ========== */

/* ==========
   Terms page
   ========== */
.terms {
  padding: 120px 0;
}

.terms h4 {
  color: #f5abcb;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.terms p,
.terms ul li {
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
}

.terms p {
  color: #666565;
  margin: 25px 0;
}

.terms p:last-of-type {
  margin-bottom: 0;
}

.terms ul {
  padding-left: 20px;
}

.terms ul li {
  color: #f5abcb;
}

.terms ul li a {
  color: #9cc0e5;
}

.terms ul li a:hover {
  color: #666565;
}
/* ==========
   End of Terms page
   ========== */

/* ==========
   Contacts page
   ========== */
.page_breadcrumbs + .contacts .parallax {
  height: 570px;
}
/* ==========
   End of Contacts page
   ========== */

/* ==========
   Team page
   ========== */
.team-page {
  padding: 120px 0;
}

.team-person {
  position: relative;
  min-height: 255px;
  background-color: #f0f1eb;
}

.team-person:not(:last-of-type) {
  margin-bottom: 40px;
}

.team-person > img {
  float: left;
  height: 255px;
  width: 270px;
  z-index: 5;
}

.person-social {
  position: absolute;
  top: 0;
  right: 0;
  padding: 45px 40px;
  height: 255px;
  width: 270px;
  text-align: center;
  background: url(../images/person-social-bg.png) left top no-repeat;
}

.about-person {
  height: 255px;
  padding: 60px 285px;
  background-color: #f0f1eb;
}

.team-person:nth-of-type(1) .person-social,
.team-person:nth-of-type(1) .person-divider {
  background-color: #c2dc71;
}
.team-person:nth-of-type(2) .person-social,
.team-person:nth-of-type(2) .person-divider {
  background-color: #9ed7c8;
}
.team-person:nth-of-type(3) .person-social,
.team-person:nth-of-type(3) .person-divider {
  background-color: #f5abcb;
}
.team-person:nth-of-type(4) .person-social,
.team-person:nth-of-type(4) .person-divider {
  background-color: #9cc0e5;
}

.team-person:nth-of-type(1) .grand-hotel {
  color: #c2dc71;
}
.team-person:nth-of-type(2) .grand-hotel {
  color: #9ed7c8;
}
.team-person:nth-of-type(3) .grand-hotel {
  color: #f5abcb;
}
.team-person:nth-of-type(4) .grand-hotel {
  color: #9cc0e5;
}

.about-person .grand-hotel {
  margin: -5px 0 0;
}

.about-person h3 {
  margin-top: -8px;
  margin-bottom: 13px;
  font-size: 30px;
  font-weight: 700;
  color: #666565;
}

.person-divider {
  width: 100px;
  height: 2px;
}

.about-person p:last-of-type,
.person-social p {
  font-size: 16px;
  line-height: 30px;
}

.about-person p:last-of-type {
  margin-top: 15px;
  color: #666565;
}

.person-social p {
  color: #fff;
  margin-bottom: 0;
}

.person-social .social-icons {
  margin: 15px auto 0;
}

.person-social .social-icons a {
  padding: 7px;
  background-color: #fff;
  opacity: 1;
}

.person-social .social-icons div {
  padding: 7px;
  background-color: #fff;
  opacity: 1;
}

.team-person:nth-of-type(1) .social-icons a {
  color: #c2dc71;
}
.team-person:nth-of-type(2) .social-icons a {
  color: #9ed7c8;
}
.team-person:nth-of-type(3) .social-icons a {
  color: #f5abcb;
}
.team-person:nth-of-type(4) .social-icons a {
  color: #9cc0e5;
}

.person-social .social-icons a.soc-facebook:hover {
  background-color: #90abd0;
}

.person-social .social-icons a.soc-twitter:hover {
  background-color: #60c7ed;
}

.person-social .social-icons a.soc-skype:hover {
  background-color: #78c7e6;
}

/*.person-social .social-icons a:hover {*/
/*background-color: #666565;*/
/*}*/

@media (max-width: 1199px) and (min-width: 992px) {
  .about-person {
    padding: 30px 285px;
  }
}

@media (max-width: 991px) {
  .team-person > img {
    float: none;
    display: block;
    margin: 0 auto 20px;
  }

  .about-person {
    padding: 30px;
    height: auto;
  }

  .person-social {
    position: relative;
    width: 100%;
    height: auto;
    padding: 20px 40px;
  }
}
/* ==========
   End of Team page
   ========== */

/* ==========
   Services page
   ========== */
.services .col-xs-12 {
  padding: 0;
  font-size: 0;
}

.single-item {
  position: relative;
  display: inline-block;
  width: 360px;
  max-width: 360px;
  margin: 15px;
  font-size: 16px;
  background-color: #f0f1eb;
}

@media (max-width: 500px) {
  .single-item {
    margin-left: 0;
    margin-right: 0;
  }
}

.single-item img {
  width: 100%;
}

.single-item-body {
  padding: 50px 30px 40px;
  text-align: center;
  background-color: #f0f1eb;
}

.single-item-body p {
  line-height: 30px;
}

.class-days {
  color: #9cc0e5;
}

.single-item-body h3 {
  margin: 0 0 5px;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 30px;
}

.single-item-body h3 a {
  color: #666566;
}

.single-item-body h3 a:hover {
  opacity: 0.5;
}

.single-item-footer {
  height: 81px;
  border-top: 1px solid #fff;
}

.single-item-footer > span {
  float: left;
  height: 100%;
  width: 33.3333333333%;
  padding: 20px 0;
  text-align: center;
}

.single-item-footer > span:last-child {
  font-size: 30px;
  font-weight: 700;
}

.single-item-footer > span:last-child span {
  font-size: 12px;
}

.single-item-footer > span:nth-of-type(2) {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.single-item-footer span.pink {
  color: #f5abcb;
}

.single-item-footer span.blue {
  color: #9cc0e5;
}

@media (max-width: 400px) {
  .single-item {
    width: 100%;
  }

  .single-item-body {
    padding: 35px 15px;
  }
}
/* ==========
   End of Services page
   ========== */

/* ==========
   Gallery Full width
   ========== */
.gallery-page .container-fluid {
  padding: 110px 80px 0;
}

.gallery-page .btn-type1 {
  height: 40px;
  width: auto;
  line-height: 36px;
  padding: 0 20px;
  margin: 5px 0;
}

.gallery-page .btn-type1:hover {
  background-color: #f5abcb;
  border-color: #f5abcb;
}

.gallery-full .isotope_container {
  margin: 60px 0;
}

.gallery-full .isotope-item {
  padding: 0;
  background-color: #f0f1eb;
}

.gallery-page .isotope-item img {
  width: 100%;
}

.gallery-full .item-title h3 {
  margin-bottom: 20px;
}

.gallery-full .item-title h3 a {
  font-size: 30px;
  font-weight: 300;
  white-space: nowrap;
  text-overflow: clip;
  color: #666565;
}

@media (max-width: 1400px) {
  .gallery-page .container-fluid {
    padding: 110px 30px;
  }

  .gallery-full .item-title h3 a {
    font-size: 24px;
    color: #666565;
  }
}

@media (max-width: 767px) {
  .gallery-page .container-fluid {
    padding: 110px 15px;
  }
}

.gallery-full .item-title h3 a:hover {
  color: #f5abcb;
}

.gallery-page .categories-links a {
  font-weight: 500;
  color: #9cc0e5;
}

.gallery-page .categories-links a:hover {
  color: #666565;
}

.gallery-page .item-title {
  margin: 30px 0;
}

.gallery-full + section {
  margin-bottom: 120px;
}
/* ==========
   End of Gallery Full width
   ========== */

/* ==========
   Gallery Regular
   ========== */
.gallery-page .pagination {
  margin: 0;
}

.isotope_container {
  margin: 60px 0;
  line-height: 0;
}

.gallery-regular .isotope-item,
.gallery-extended .isotope-item {
  margin-bottom: 30px;
}

.gallery-full .isotope-item,
.gallery-regular .isotope-item {
  max-width: 440px;
}
/* ==========
   End of Gallery Regular
   ========== */

/* ==========
   Gallery Extended
   ========== */
.gallery-extended .isotope_container {
  margin-bottom: 0;
}

.gallery-extended .isotope-item {
  line-height: 30px;
  font-size: 16px;
}

.gallery-extended .item-content {
  padding: 30px 15px;
  background-color: #f0f1eb;
}

.gallery-extended .item-content p {
  max-width: 450px;
  margin: 0 auto;
  color: #666566;
}

.gallery-extended .item-title {
  margin: 10px 0 25px;
}

.gallery-extended .item-title a {
  font-size: 28px;
  font-weight: 700;
  color: #666566;
}

.gallery-extended .item-title a:hover {
  opacity: 0.5;
}
/* ==========
   End of Gallery Extended
   ========== */

/* ==========
   Calendar
   ========== */
.calendar {
  padding: 120px 0;
}

.calendar-header {
  margin-bottom: 40px;
}

.calendar-navigation {
  position: relative;
  text-align: center;
}

.calendar-navigation h3 {
  font-size: 30px;
  font-weight: 700;
  color: #666565;
  margin: 0;
}

.calendar-navigation button {
  position: absolute;
  top: 5px;
  padding: 0;
  font-size: 14px;
  color: #818181;
  background: none;
  border: none;
}

.calendar-navigation button:hover {
  color: #666565;
}

.calendar-navigation button.prev {
  left: 15px;
}

.calendar-navigation button.next {
  right: 15px;
}

.week-days,
.week-row,
.month-box {
  font-size: 0;
}

.week-days > div {
  position: relative;
  display: inline-block;
  width: 14.285714%;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  background-color: #666565;
}

.week-days > div:before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  width: 100%;
  height: 5px;
}

.week-days > .sunday:before {
  background-color: #f5abcb;
}
.week-days > .monday:before {
  background-color: #9cc0e5;
}
.week-days > .tuesday:before {
  background-color: #c2dc71;
}
.week-days > .wednesday:before {
  background-color: #9ed7c8;
}
.week-days > .thursday:before {
  background-color: #f5abcb;
}
.week-days > .friday:before {
  background-color: #9cc0e5;
}
.week-days > .saturday:before {
  background-color: #c2dc71;
}

.day-cell {
  position: relative;
  padding: 30px 0;
  display: inline-block;
  width: 14.285714286%;
  text-align: center;
  border: 1px solid #d9dadc;
  cursor: pointer;
}

.day-cell.not-cur-month {
  cursor: default;
}

.day-cell.today {
  background-color: #f5abcb;
}

.day-cell.today > * {
  color: #fff;
}

.day-cell.today:before {
  content: "TODAY";
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 16px;
  color: #fff;
}

.week-row:not(:first-child) .day-cell {
  border-top: none;
}

.day-cell:not(:first-child) {
  border-left: none;
}

.day-cell * {
  font-size: 16px;
}

.day-date {
  color: #666565;
}

.not-cur-month .day-date {
  color: #b2b2b2;
}

.day-events {
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: #b2b2b2;
}

.day-cell.not-cur-month .day-events {
  display: none;
}

.events-list {
  padding: 15px 30px;
  background-color: #e6eff8;
}

@media (min-width: 768px) {
  .events-list {
    border-right: 1px solid #fff;
  }
}

.events-list h4 {
  margin: 5px 0;
  font-size: 16px;
  font-weight: 500;
  color: #666565;
}

.events-list + .week-row .day-cell,
.appointment-list + .week-row .day-cell {
  border-top: 1px solid #d9dadc;
}

.selected-day:after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 50%;
  -webkit-transform: translateX(-9px);
  -moz-transform: translateX(-9px);
  -ms-transform: translateX(-9px);
  -o-transform: translateX(-9px);
  transform: translateX(-9px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 9px 0 9px;
  border-color: #fff transparent transparent transparent;
}

@media (max-width: 639px) {
  .day-cell.today:before,
  .day-cell * {
    font-size: 12px;
  }

  .day-cell.today:before {
    top: 0;
    left: 5px;
  }

  .week-days > div {
    padding: 10px 0;
    font-size: 12px;
  }

  .day-cell {
    padding: 20px 0;
  }

  .day-events {
    bottom: 0;
    right: 5px;
  }
}

@media (max-width: 439px) {
  .day-cell.today:before,
  .day-cell * {
    font-size: 8px;
  }

  .day-cell .day-date {
    font-size: 12px;
  }

  .day-cell {
    padding: 15px 0;
  }

  .events-list h4 {
    font-size: 12px;
  }
}
/* ==========
   End of Calendar
   ========== */

/* ==========
   Appointment page
   ========== */

.appointment-calendar .today {
  background-color: #9cc0e5;
}

.appointment-calendar .calendar-header {
  margin-bottom: 0;
}

.appointment-calendar .calendar-navigation {
  position: relative;
  background-color: #f5abcb;
  padding: 20px 0 15px;
}

.appointment-calendar .calendar-navigation h3 {
  display: inline-block;
  color: #fff;
  vertical-align: middle;
}

@media (max-width: 480px) {
  .appointment-calendar .calendar-navigation h3 {
    display: block;
  }
}

@media (max-width: 640px) {
  .boxed .appointment-calendar .calendar-navigation h3 {
    display: block;
  }
}

.appointment-calendar .calendar-navigation a {
  color: #fff;
  font-size: 16px;
  vertical-align: middle;
  margin-left: 20px;
}

.appointment-calendar .calendar-navigation button {
  top: 50%;
  margin-top: -7px;
  color: #fff;
}

.appointment-calendar .calendar-navigation button:hover {
  color: #666565;
}

.appointment-calendar .calendar-navigation button.prev {
  left: 30px;
}

.appointment-calendar .calendar-navigation button.next {
  right: 30px;
}

.appointment-calendar .week-days > div:before {
  display: none;
}

.appointment-calendar .week-days > div:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -5px;
  width: 100%;
  height: 5px;
}

.appointment-calendar .week-days {
  margin-bottom: 5px;
}

.appointment-calendar .week-days > .sunday:after {
  background-color: #f5abcb;
}
.appointment-calendar .week-days > .monday:after {
  background-color: #9cc0e5;
}
.appointment-calendar .week-days > .tuesday:after {
  background-color: #c2dc71;
}
.appointment-calendar .week-days > .wednesday:after {
  background-color: #9ed7c8;
}
.appointment-calendar .week-days > .thursday:after {
  background-color: #f5abcb;
}
.appointment-calendar .week-days > .friday:after {
  background-color: #9cc0e5;
}
.appointment-calendar .week-days > .saturday:after {
  background-color: #c2dc71;
}

.appointment-list {
  padding: 40px;
  background-color: #666565;
}

@media (min-width: 768px) {
  .appointment-list {
    border-right: 1px solid #fff;
  }
}

.appointment-list h4 {
  font-size: 20px;
  font-weight: 300;
  color: #fff;
}

.appointment-list ul {
  margin: 0;
  padding: 0;
}

.appointment-list li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.appointment-list li {
  padding: 5px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.appointment-list li div * {
  font-size: 16px;
}

.appointment-list li > div {
  height: 40px;
  line-height: 40px;
  display: inline-block;
}

.appointment-time span {
  margin-left: 15px;
  color: #fff;
}

.appointment-slots {
  margin-left: 110px;
}

@media (max-width: 991px) {
  .appointment-slots {
    margin-left: 40px;
  }

  .boxed .appointment-list li > div {
    display: block;
    float: none;
  }

  .boxed .appointment-slots {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .appointment-list li > div {
    display: block;
    float: none;
  }

  .appointment-slots {
    margin-left: 0;
  }
}

.appointment-slots span {
  color: #f5abcb;
}

.unavailable .appointment-slots span {
  color: #b2b2b2;
}

.book-appointment {
  float: right;
}

.appointment-list li i {
  color: #f5abcb;
}

.appointment-list .btn-type1 {
  margin-top: 0;
  width: 200px;
  height: 40px;
  line-height: 40px;
  border: none;
  color: #818181;
  background-color: #fff;
}

.appointment-list .btn-type1:hover {
  background-color: #f5abcb;
}

.unavailable .btn-type1 {
  background-color: #818181;
  color: #fff;
}

.unavailable .btn-type1:hover {
  background-color: #818181;
  color: #fff;
  cursor: default;
}
/* ==========
   End of Appointment page
   ========== */

/* ==========
   Blog
   ========== */
/*
Top slider
*/
.blog-top-slider {
  padding: 60px 0;
  background-color: #f0f1eb;
}

.blog-top-slider .slide_description_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 11% 10px 0;
  text-align: center;
  background-color: rgba(41, 42, 46, 0.2);
}

.blog-top-slider .slide_description {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

.blog-top-slider .slide_description p {
  display: inline-block;
  color: #f5abcb;
  padding: 0 10px 15px;
  font-size: 16px;
  letter-spacing: 5px;
  border-bottom: 3px solid #f5abcb;
}

.blog-top-slider .slide_description h3 {
  margin: 35px 0;
  color: #fff;
}

@media (max-width: 1199px) {
  .blog-top-slider .slide_description_wrapper {
    padding-top: 5%;
  }

  .blog-top-slider .slide_description h3 {
    font-size: 30px;
  }
}

.btn-type2 {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-top: 10px;
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 65px;
  background-color: #9cc0e5;
  border-radius: 50%;
}

.btn-type2:hover {
  color: #fff;
  background-color: #666565;
}

.blog-top-slider .flex-direction-nav {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 55px;
  text-align: center;
  z-index: 2;
}

@media (max-width: 991px) {
  .blog-top-slider .slide_description h3 {
    margin: 0;
  }

  .blog-top-slider .flex-direction-nav {
    bottom: 30px;
  }
}

@media (max-width: 767px) {
  .blog-top-slider .slide_description_wrapper {
    position: relative;
    padding-bottom: 130px;
    background-color: transparent;
  }
}

.blog-top-slider .flex-direction-nav li {
  display: inline-block;
  margin: 5px;
  width: 60px;
  height: 60px;

  border: 2px solid #fff;
}

.blog-top-slider .flex-direction-nav li a {
  display: block;
  height: 100%;
  line-height: 56px;
  font-family: "rt-icons-2" !important;
  speak: none;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.blog-top-slider .flex-direction-nav .flex-prev:before {
  content: "\e7c4";
}

.blog-top-slider .flex-direction-nav .flex-next:before {
  content: "\e7c5";
}

.blog-top-slider .flex-direction-nav li:hover {
  background-color: #f5abcb;
  border: 2px solid #f5abcb;
  -webkit-transition: 200ms;
  -moz-transition: 200ms;
  -ms-transition: 200ms;
  -o-transition: 200ms;
  transition: 200ms;
}
/*
End of Top slider
*/

/*
Top Posts
*/
.top-posts h3 {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: #666666;
  margin: 60px 0 50px;
}

.top-post-item {
  max-width: 270px;
  width: 100%;
  margin: 0 0 30px;
  background-color: transparent;
}

.top-post-item .single-item-body {
  padding-top: 30px;
}

.top-post-item .single-item-body h3 {
  font-size: 20px;
  letter-spacing: -1px;
  font-weight: 300;
  margin: 15px 0;
}

.top-post-item .single-item-footer {
  border-top: none;
}

.top-post-item .single-item-footer span {
  font-size: 12px;
  color: #fff;
  font-weight: 700;
  line-height: 16px;
  background-color: #666565;
}

.top-post-item .single-item-footer > span:last-child {
  font-size: 12px;
}

.top-post-item .single-item-footer i {
  font-size: 24px;
  color: #f5abcb;
}

.top-post-item .single-item-footer > span:nth-of-type(2) {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.top-post-item .post-date {
  font-size: 12px;
}
/*
End of Top Posts
*/

/*
Video Post
*/
.blog-post.single-item {
  margin: 0;
  width: 100%;
  max-width: none;
}

.embed-placeholder:before {
  -webkit-transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px);
  transform: translateY(-15px);
}

.single-item.video-post .video-container {
  position: relative;
  padding-bottom: 66.5%;
}

.blog-post.single-item .single-item-body {
  position: relative;
  padding: 60px;
  height: auto;
  text-align: left;
  overflow: hidden;
}

.video-post .single-item-body {
  border: 2px solid #f5abcb;
}

@media (max-width: 991px) and (min-width: 768px) {
  .blog-post.single-item .single-item-body {
    padding: 60px 20px;
  }
}

@media (max-width: 499px) {
  .blog-post.single-item .single-item-body {
    padding: 60px 20px;
  }
}

.icon-body:before,
.icon-body:after {
  font-size: 24px;
  font-family: "rt-icons-2" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
}

.video-post .single-item-body:after {
  content: "\e71c";
  color: #f0f1eb;
  position: absolute;
  top: 4px;
  right: 4px;
}

.video-post .single-item-body:before {
  content: "";
  position: absolute;
  top: -6px;
  right: -26px;
  width: 0;
  height: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  border-style: solid;
  border-width: 0 40px 40px 40px;
  border-color: transparent transparent #f5abcb transparent;
}

.post-date {
  color: #9cc0e5;
}

.post-category a {
  color: #f5abcb;
}

.post-category a:hover {
  color: #666566;
}

.blog-post h3 a {
  font-weight: 700;
  color: #666566;
  margin: 0;
}

.post-descr {
  margin: 20px 0;
}
/*
End of Video Post
*/

/*
Blog Post Small
*/
.single-item.blog-post-small {
  margin: 40px 0 0;
  max-width: 360px;
  width: 100%;
}

.single-item.blog-post-small:nth-of-type(odd) {
  margin-right: 29px;
}

@media (max-width: 1199px) {
  .single-item.blog-post-small:nth-of-type(odd) {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .single-item.blog-post-small,
  .single-item.blog-post-small:nth-of-type(odd) {
    display: block;
    margin: 40px auto 0;
  }
}

.zero-font {
  font-size: 0;
}

.blog-post-small .single-item-body {
  padding: 35px;
  height: auto;
}

.blog-post-small h3 a {
  font-size: 20px;
  font-weight: 700;
  margin: 15px 0;
}

.blog-post-small .post-descr {
  max-width: 270px;
  margin: 20px auto;
  height: 90px;
}
/*
End of Blog Post Small
*/

/*
Image Post
*/
.single-item.image-post {
  margin-top: 40px;
}
/*
End of Image Post
*/
/*
Status Post
*/
.single-item.status-post {
  margin-top: 40px;
  margin-bottom: 40px;
}

.post-image-bg {
  position: relative;
}

.post-image-bg img {
  display: block;
}

.post-image-bg:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(102, 101, 101, 0.95);
}

.status-post .item-media,
.quote-post .item-media {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 60px 20px;
  text-align: center;
}

@media (max-width: 1199px) {
  .status-post .item-media,
  .quote-post .item-media {
    padding: 20px;
  }

  .status-post .post-descr,
  .quote-post .post-descr {
    margin: 10px 0;
  }
}

.status-post .item-media img {
  width: auto;
  height: auto;
  margin-bottom: 15px;
}

.status a {
  color: #f5abcb;
  font-weight: 700;
}

.status-post .post-descr,
.quote-post .post-descr {
  color: #fff;
}

@media (max-width: 991px) and (min-width: 768px) {
  .status-post .item-media,
  .quote-post .item-media {
    position: relative;
  }

  .status-post .post-descr,
  .quote-post .post-descr {
    color: #666566;
  }
}

@media (max-width: 660px) {
  .status-post .item-media,
  .quote-post .item-media {
    position: relative;
  }

  .status-post .post-descr,
  .quote-post .post-descr {
    color: #666566;
  }
}
/*
End of Status Post
*/

/*
Link Post
*/
.link-post .post-category,
.link-post .post-date {
  margin: 0;
  line-height: 22px;
}

.link-post h3 {
  margin: 30px 0 0;
}

.link-post h3 a {
  font-weight: 300;
  font-style: italic;
}
/*
End of Link Post
*/

/*
Carousel Post
*/
.single-item.slider-post {
  margin: 40px 0;
  overflow: hidden;
}

.carousel-control {
  border: 1px solid #ffffff;
  color: #ffffff !important;
  border-radius: 0px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  left: -50px;
  top: 50%;
  margin-top: -25px;
  text-shadow: none;
  opacity: 1;
  background-image: none !important;
  filter: alpha(opacity=100);
}

.carousel-control.right {
  right: -50px;
}

.carousel:hover .carousel-control {
  left: 50px;
}

.carousel:hover .carousel-control.right {
  left: auto;
  right: 50px;
}

.carousel-control .icon-prev:before {
  content: "\e7c4";
}

.carousel-control .icon-next:before {
  content: "\e7c5";
}

.carousel-control .icon-body:before {
  color: #fff;
  font-size: 16px;
  vertical-align: middle;
}

.carousel-control .icon-body {
  font-size: 16px;
  height: auto;
  width: auto;
}

.slider-post .post-date {
  margin-bottom: 0;
}

.slider-post h3 {
  margin-top: 10px;
}
/*
End of Carousel Post
*/

/*
Quote Post
*/
.quote-post .item-media {
  padding: 55px 60px;
  text-align: left;
}

.quote-post .item-media p {
  margin: 0;
  line-height: 18px;
}

.quote-post p.post-descr {
  margin: 30px 0;
  font-size: 28px;
  line-height: 30px;
  font-style: italic;
}

.quote-author {
  color: #fff;
}

.quote-author a {
  color: #9cc0e5;
}

@media (max-width: 1200px) {
  .quote-post .item-media {
    padding: 30px 60px;
  }

  .quote-post p.post-descr {
    margin: 15px 0;
    font-size: 24px;
    line-height: 26px;
  }
}

@media (max-width: 480px) {
  .quote-post .item-media {
    padding: 20px;
  }
}
/*
End of Quote Post
*/

/*
Chat Post
*/
.single-item.chat-post {
  margin: 40px 0;
}

.chat-block {
  margin-bottom: 20px;
}

.comment-author strong {
  font-weight: 700;
}

.chat-comment > div {
  padding: 20px 0;
}

div.sub-comment {
  padding-left: 20px;
  background-color: #fff;
}

@media (max-width: 540px) {
  .single-item.chat-post .single-item-body {
    padding: 60px 20px;
  }
}
/*
End of Chat Post
*/

/*
Image Small Post
*/
.single-item.image-small-post {
  margin-top: 40px;
  overflow: hidden;
}

.single-item.image-small-post .single-item-body {
  padding: 40px 40px 40px 20px;
}

.small-chat a.btn-type1 {
  margin-top: 0;
}

@media (max-width: 991px) {
  .single-item.image-small-post {
    max-width: 375px;
  }
}
/*
End of Image Small Post
*/

/*
Pagination
*/
.pagination {
  margin: 40px 0 100px;
}

.pagination * {
  font-size: 16px;
}

/*.pagination li i {*/
/*vertical-align: m;*/
/*}*/

.pagination li a {
  width: 40px;
  height: 40px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  margin: 0 10px 10px 0;
  color: #666565;
  border: 2px solid #666565;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > li > a:hover {
  color: #fff;
  background-color: #f5abcb;
  border: 2px solid transparent;
}

.pagination li a i {
  vertical-align: middle;
}

.pagination > li:first-child > a,
.pagination > li:last-child > a {
  background-color: #9cc0e5;
  color: #fff;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.pagination > li:first-child > a:hover,
.pagination > li:last-child > a:hover {
  background-color: #666565;
}
/*
End Pagination
*/
/* ==========
   End of Blog
   ========== */

/* ==========
   Sidebar
   ========== */
@media (max-width: 767px) {
  aside {
    padding-top: 60px;
  }
}

aside h3.widget-title {
  font-size: 30px;
  font-weight: 700;
  color: #666666;
  margin: 0 0 35px;
  text-align: center;
}

aside .widget {
  margin-bottom: 40px;
  padding: 0;
}

aside a.big-icon {
  width: 116px;
  height: 116px;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}

aside a.color-bg-icon {
  color: #ffffff;
  background-color: #323232;
  margin-bottom: 4px;
  padding: 20px 12px;
}

aside a.social-icon {
  display: inline-block;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
}

aside a.social-icon span {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
}

aside a.social-icon span:first-child {
  font-size: 20px;
  margin-top: 8px;
  font-weight: bold;
}

aside a.big-icon[class*="soc-"]:before {
  font-size: 32px;
}

.color-bg-icon.soc-twitter {
  background-color: #666666;
}

.color-bg-icon.soc-spanish {
  background-color: #ff9898;
}
.color-bg-icon.soc-english {
  background-color: #507cbe;
}
.color-bg-icon.soc-facebook {
  background-color: #507cbe;
}

.color-bg-icon.soc-google {
  background-color: #d93e2d;
}

a.color-bg-icon:hover {
  background-color: #f5abcb;
  color: #ffffff !important;
}

.selected {
  border-bottom-style: outset;
  border-bottom-width: 4px;
}

aside .widget_mailchimp {
  text-align: center;
}
aside .widget_search [type="text"],
aside .widget_mailchimp .mailchimp_email {
  color: rgba(102, 101, 101, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

aside .widget_mailchimp p {
  color: #666566;
  margin-bottom: 10px;
}

aside .widget_mailchimp button {
  color: #9cc0e5;
}

aside .widget_tag_cloud .btn-type1 {
  width: auto;
  height: 42px;
  padding: 0 18px;
  margin: 0 5px 9px 0;
  line-height: 38px;
}

aside .widget_categories,
aside .widget_archive {
  margin-top: 40px;
  padding: 35px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.widget_categories .select-group,
.widget_archive .select-group {
  position: relative;
  display: inline-block;
  max-width: 290px;
  width: 100%;
}

.widget_categories select,
.widget_archive select {
  padding: 12px 30px;
  height: 60px;
  width: 100%;
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  font-style: italic;
  color: rgba(102, 101, 101, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.widget_categories select:focus,
.widget_archive select:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  border-color: rgba(0, 0, 0, 0.1);
}

.widget_categories select:hover,
.widget_archive select:hover {
  cursor: pointer;
}

.widget_categories .select-group i,
.widget_archive .select-group i {
  position: absolute;
  top: 23px;
  right: 23px;
  color: #9cc0e5;
}

.widget_categories .select-group:hover i,
.widget_archive .select-group:hover i {
  color: #666566;
}

.widget_recent_posts img {
  max-width: 100px;
}

.widget_recent_posts .media-body p a {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: #666566;
}

.widget_recent_posts .post-date {
  font-size: 12px;
}

.widget_recent_posts .media-left {
  padding-right: 15px;
}
/* ==========
   End of Sidebar
   ========== */

/* ==========
   Blog Post
   ========== */
.light-bg {
  background-color: #f0f1eb;
}

.single-item.full-post {
  background-color: transparent;
}

.full-post .single-item-body {
  background-color: transparent;
}

.full-post .full-width {
  margin-top: -60px;
  margin-left: -60px;
  margin-right: -60px;
}

@media (max-width: 991px) and (min-width: 768px) {
  .padding-60 {
    padding: 60px 20px;
  }

  .full-post .full-width {
    margin-top: -60px;
    margin-left: -20px;
    margin-right: -20px;
  }
}

@media (max-width: 500px) {
  .padding-60 {
    padding: 60px 15px;
  }

  .full-post .full-width {
    margin-top: -60px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.single-item.full-post .single-item-body {
  padding: 60px 0 0;
}

.full-post .post-adds {
  line-height: 0;
  background-color: #fff;
}

.full-post .post-adds > * {
  display: inline-block;
  padding-top: 14px;
  padding-bottom: 12px;
  vertical-align: middle;
}

.full-post .post-adds > a {
  width: 50px;
  text-align: center;
}

.theme_button {
  background-color: #666566;
}

.theme_button i {
  color: #fff;
  font-size: 24px;
}

.theme_button.inverse {
  background-color: transparent;
}

.theme_button.inverse i {
  color: #f5abcb;
}

.theme_button:hover i {
  opacity: 0.5;
}

.full-post .post-adds {
  margin: 30px 0;
}

.full-post .post-adds > a:nth-of-type(2) {
  border-right: 1px solid #f0f1eb;
}

.full-post .post-adds > span {
  padding-right: 20px;
  padding-left: 20px;
}

.full-post .post-adds .views-count {
  line-height: 26px;
  padding: 12px;
}

strong {
  font-weight: 700 !important;
}

.views-count {
  color: #fff;
  background-color: #9cc0e5;
}

.views-count strong {
  color: #fff;
}

.entry-content p {
  line-height: 30px;
}

.full-post blockquote {
  position: relative;
  margin: 30px 0;
  padding: 10px 20px 20px 42px;
  border-left: 5px solid #f5abcb;
  font-size: 20px;
  line-height: 30px;
  font-style: italic;
}

blockquote .media-object {
  width: auto;
  max-width: 62px;
  border-radius: 50%;
}

.highlight {
  color: #f5abcb;
}

blockquote .media:first-child {
  margin-top: 15px;
}

blockquote .media-body {
  line-height: 20px;
  font-style: normal;
}

.list2 {
  margin: 30px 0;
  padding-left: 20px;
}

.list2 li {
  color: #f5abcb;
}

.alignleft {
  float: left;
  margin: 5px 30px 15px 0;
  max-width: 50%;
}

.blog-more-toggle {
  display: block;
  padding: 12px 0;
  margin: 32px 0 80px;
  background-color: #666566;
}

.blog-more-toggle > span {
  color: #fff;
  font-weight: 500;
}

.blog-more-toggle.collapsed .blog-more-hidden {
  display: inline-block;
}

.blog-more-toggle.collapsed .blog-more-visible {
  display: none;
}

.blog-more-toggle .blog-more-hidden {
  display: none;
}

.author-meta {
  font-size: 16px;
  color: #666566;
  background-color: #fff;
}

.author-meta .item-content {
  padding: 0 20px;
  position: relative;
}

@media (max-width: 500px) {
  .author-meta .item-content {
    padding-right: 50px;
  }
}

.author-meta .entry-title {
  margin-top: 25px;
  font-size: 30px;
  font-weight: 700;
  padding: 16px 0;
}

.author-meta .entry-title:after {
  content: "";
  position: relative;
  bottom: -16px;
  display: block;
  height: 2px;
  width: 120px;
  background-color: #f5abcb;
}

.author-meta p {
  max-width: 320px;
  margin-top: 10px;
  margin-bottom: 25px;
  line-height: 30px;
}

.author-meta .author-social {
  position: absolute;
  top: 20px;
  right: 20px;
}

.author-meta .author-social a {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 15px;
  padding: 7px;
}

/*
Comment Form
*/
.comment-respond {
  margin: 60px 0;
}

.comment-form {
  font-size: 16px;
}

.comment-form label {
  display: none;
}

.comment-form input,
.comment-form textarea {
  font-style: italic;
}

.comment-form .form-control {
  height: 40px;
  border: none;
  background-color: #ffffff;
  border-radius: 0;
  color: #1a1a1a;
  padding-left: 20px;
  padding-right: 40px;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 16px;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

#comment {
  height: 120px;
  resize: none;
}

.comment-form .row {
  margin: 0 -5px;
}

.comment-form [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.btn-type1.colore-blue {
  color: #fff;
  background-color: #9cc0e5;
  border-color: #9cc0e5;
}

.btn-type1.colore-blue:hover {
  background-color: #666566;
  border-color: #666566;
}
/*
End of Comment Form
*/

/*
Comment
*/
.comments-area {
  font-size: 16px;
  line-height: 30px;
  color: #666566;
}

@media (max-width: 500px) {
  .comments-area {
    font-size: 12px;
    line-height: 22px;
  }
}

.comment-list {
  padding-left: 0;
}

.comments-area .media-object {
  max-width: 75px;
}

.comments-area li {
  list-style: none;
  padding-top: 15px;
}

.comments-area .media {
  position: relative;
}

.comments-area .media-left {
  padding-right: 0;
}

.comments-area .media-body {
  padding: 10px 0 0 30px;
}

.reply {
  position: absolute;
  right: -1px;
  top: -1px;
}

.comment-meta .comment-date {
  display: block;
  font-size: 12px;
  line-height: 12px;
  color: #9cc0e5;
}

.comment-meta .author_url {
  font-size: 20px;
  font-weight: 700;
  color: #f5abcb;
}

.reply a {
  font-size: 20px;
  color: #9cc0e5;
}

.reply a:hover {
  color: #666566;
}
/*
End of Comment
*/

/*
Prev/Next Buttons
*/
.nav-buttons {
  margin-top: 40px;
}

.blog-btn {
  display: inline-block;
  margin: 20px auto 0;
  max-width: 380px;
  position: relative;
}

.blog-btn:hover {
  cursor: pointer;
}

.blog-btn .item-media {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 10%;
  font-size: 16px;
  text-align: center;
  color: #f5abcb;
  background-color: rgba(102, 101, 101, 0.8);
}

@media (max-width: 350px) {
  .blog-btn .item-media {
    padding: 10% 5%;
  }
}

.blog-btn:hover .item-media {
  background-color: rgba(102, 101, 101, 0.5);
}

.blog-btn .item-media p {
  margin-bottom: 0;
}

.blog-btn .item-media p:last-child {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 1199px) and (min-width: 768px) {
  .blog-btn .item-media {
    font-size: 12px;
  }

  .blog-btn .item-media p:last-child {
    font-size: 14px;
    line-height: 20px;
  }
}
/*
End of Prev/Next Buttons
*/
/* ==========
   End of Blog Post
   ========== */

/* ==========
   Full Video Blog Post
   ========== */
@media (max-width: 991px) {
  .padding-r20 {
    padding-right: 0;
  }
}

.full-video-cont {
  background-color: #f0f1eb;
}

.full-video-post .single-item .single-item-body {
  padding: 0;
}

.full-video-post .post-adds {
  background-color: #f0f1eb;
}

.full-video-post .post-adds > a:nth-of-type(2) {
  border-right: 1px solid #fff;
}

.full-video-post .author-meta {
  background-color: #f0f1eb;
}

.full-video-post .item-media img {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
/* ==========
   End of Full Video Blog Post
   ========== */

/* ==========
   Team Member
   ========== */
.member-meta img {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.member-meta .author-social {
  display: block;
  margin: 25px 0;
}

.member-meta .author-social {
}

.member-meta .scial-icon {
  display: inline-block;
}

.member-contacts {
  margin-bottom: 50px;
}

.member-contacts > div {
  padding: 20px 0 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.member-contacts > div:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.member-contacts > div > * {
  vertical-align: middle;
}

.mbr-vocation {
  display: block;
  font-size: 30px;
  line-height: 30px;
}

/*
Tab
*/

.tab-container {
  margin: 30px 0;
}

.nav-tabs > li > a {
  border-radius: 0;
}

.light-green-tab .nav-tabs > li > a {
  padding: 15px 30px;
  color: #323232;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

@media (max-width: 450px) {
  .light-green-tab .nav-tabs > li > a {
    font-size: 14px;
    padding: 10px;
  }
}

.light-green-tab .nav-tabs > li.active > a {
  color: #fff;
  border-color: transparent;
  background-color: #c2dc71;
}

.light-green-tab .tab-content {
  padding: 20px 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #c2dc71;
}

.tab-content h4 {
  margin: 20px 0;
  font-size: 20px;
}
/*
End of Tab
*/
/*
Member testimonial
*/
.mbr-testimonial {
  margin: 40px 0;
  position: relative;
  background-color: #fafafa;
}

.mbr-testimonial > i {
  font-size: 40px;
  position: absolute;
  top: 25px;
  left: 30px;
}

.mbr-testimonial-body {
  margin-left: 60px;
  padding: 30px;
}

.mbr-testimonial-body p {
  font-size: 20px;
  font-style: italic;
}

@media (max-width: 500px) {
  .mbr-testimonial > i {
    left: 30px;
  }

  .mbr-testimonial-body p {
    font-size: 14px;
  }
}

.mbr-testimonial img {
  max-width: 70px;
  border-radius: 50%;
  vertical-align: middle;
}

.mbr-testimonial .testimonial-author {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.mbr-testimonial .testimonial-author span:first-child {
  display: block;
}
/*
End of Member testimonial
*/
/* ==========
End of Team Member
========== */

/* ==========
Courses
========== */
.courses h2 {
  margin-top: 50px;
  margin-bottom: 30px;
}

.courses .list2 li {
  margin: 12px 0;
}

.table1 {
  width: 100%;
}

.courses-table th:last-child,
.courses-table td:last-child {
  width: 100px;
  text-align: center;
}

.table1 td,
.table1 th {
  padding: 20px 30px;
}

.table1 th {
  font-weight: 300;
  color: #fff;
  background-color: #666565;
}

.table1 td {
  background-color: #f0f1eb;
}

.courses-table.table1 td {
  color: #9cc0e5;
  border-left: none;
}

.table1 tr:first-child {
  border-bottom: 5px solid #f5abcb;
}

.table1 tr {
  border-bottom: 1px solid #fff;
}

.table1 i {
  font-size: 24px;
}

/*
Sidebar
*/
.progress-head {
  margin-bottom: 5px;
}

.progress-head span {
  float: right;
}

.progress {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f0f1eb;
}

.progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress-bar.pink {
  background-color: #f5abcb;
}

.progress-bar.blue {
  background-color: #9cc0e5;
}

.progress-bar.light-green {
  background-color: #c2dc71;
}

.progress-bar.turquoise {
  background-color: #9ed7c8;
}

.progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 20px 20px;
  background-size: 20px 20px;
}
/*
End of Sidebar
*/
/* ==========
End of Courses
========== */

/* ==========
Calculator
========== */
.service-calc h2 {
  margin-top: 35px;
  margin-bottom: 30px;
}

.service-calc input[type="radio"],
.service-calc input[type="checkbox"] {
  display: none;
}

.service-calc input[type="radio"] + label:before,
.service-calc input[type="checkbox"] + label:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  color: #666566;
  border: 1px solid rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  margin-right: 10px;
}

.service-calc input[type="radio"] + label:before {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.service-calc input[type="radio"] + label:hover,
.service-calc input[type="checkbox"] + label:hover {
  cursor: pointer;
}

.service-calc input[type="radio"]:checked + label:before {
  background: #666566;
  background: -webkit-radial-gradient(circle, #666566 50%, transparent 61%);
  background: -o-radial-gradient(circle, #666566 50%, transparent 61%);
  background: -moz-radial-gradient(circle, #666566 50%, transparent 61%);
  background: radial-gradient(circle, #666566 50%, transparent 61%);
}

.service-calc input[type="checkbox"]:checked + label:before {
  content: "\e116";
  font-size: 10px;
  font-family: "rt-icons-2" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.service-calc input {
  display: block;
  padding: 20px 30px;
  width: 100%;
  font-style: italic;
  border: 1px solid rgba(0, 0, 0, 0.1);
  outline: none;
}

.service-calc label {
  font-weight: 300;
}

.calculated {
  color: #f5abcb;
}
/* ==========
End of Calculator
========== */

/* ==========
Typography
========== */
.typography h1,
.typography h2 {
  margin-top: 80px;
  margin-bottom: 40px;
}

.heading-block > * {
  margin-bottom: 25px !important;
}

.tm-quote {
  max-width: 760px;
  margin: 50px auto;
}

.tm-quote p {
  margin: 30px 0;
  font-size: 20px;
  font-style: italic;
}
/* ==========
End of Typography
========== */

/* ==========
Elements
========== */
.member-card {
  display: inline-block;
  max-width: 370px;
  margin-bottom: 30px;
}

.member-card img {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.member-card .author-social {
  width: 100%;
  padding: 15px 0 12px;
  margin: 0;
  background-color: #666566;
}

.member-card .author-social a {
  padding: 7px 7px 5px 7px;
}

.member-card h2 {
  margin-bottom: 10px;
}

.member-card .single-item-body {
  height: auto;
  padding: 40px 15px;
}

.member-card .single-item-body > span {
  display: block;
  margin-bottom: 25px;
}

.member-card p {
  max-width: 250px;
  margin: auto;
}

.member-card a.bg-icon {
  color: #666566;
  background-color: #fff;
  margin-bottom: 4px;
  opacity: 1;
}

a.bg-icon:hover {
  color: #fff;
}

a.bg-icon.soc-facebook:hover {
  background-color: #90abd0;
}

a.bg-icon.soc-twitter:hover {
  background-color: #60c7ed;
}

a.bg-icon.soc-google:hover {
  background-color: #e28679;
}

.right-bnts {
  height: 100%;
  vertical-align: bottom;
}

.buttons-block a {
  margin-right: 10px;
}

.testimonials.reset-bg p {
  color: #666566;
}

.inline-icon-block > div {
  display: inline-block;
  vertical-align: text-top;
}

.inline-icon-block > div:last-child {
  margin-left: 15px;
  width: calc(100% - 116px);
}

.alert {
  padding: 20px 35px;
  margin-bottom: 30px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.alert-success,
.alert-info,
.alert-warning,
.alert-danger {
  border: none;
}

.alert-success {
  color: #18976e;
  background-color: #c5e7de;
}

.alert-info {
  color: #5892ca;
  background-color: #c4d9ef;
}

.alert-warning {
  color: #68a200;
  background-color: #daeaaa;
}

.alert-danger {
  color: #d93775;
  background-color: #f9cde0;
}

.alert button span:first-child {
  display: none;
}

.alert:hover button span:first-child {
  display: inline;
}

.alert i {
  font-size: 20px;
}

.table1 td {
  border-left: 1px solid #fff;
}

.table1 td:first-child {
  border-left: none;
}
/* ==========
End of Elements
========== */
