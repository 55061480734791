/*
==============================================
CSS3 ANIMATION CHEAT SHEET
==============================================

Made by Justin Aguilar

www.justinaguilar.com/animations/

Questions, comments, concerns, love letters:
justin@justinaguilar.com
==============================================
*/

/*
==============================================
slideDown
==============================================
*/


.slideDown{
	animation-name: slideDown;
	-webkit-animation-name: slideDown;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	

	visibility: visible !important;						
}

@keyframes slideDown {
	0% {
		transform: translateY(-100%);
	}
	50%{
		transform: translateY(8%);
	}
	65%{
		transform: translateY(-4%);
	}
	80%{
		transform: translateY(4%);
	}
	95%{
		transform: translateY(-2%);
	}			
	100% {
		transform: translateY(0%);
	}		
}

@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%);
	}
	50%{
		-webkit-transform: translateY(8%);
	}
	65%{
		-webkit-transform: translateY(-4%);
	}
	80%{
		-webkit-transform: translateY(4%);
	}
	95%{
		-webkit-transform: translateY(-2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
	}	
}

/*
==============================================
slideUp
==============================================
*/


.slideUp{
	animation-name: slideUp;
	-webkit-animation-name: slideUp;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;

	visibility: visible !important;			
}

@keyframes slideUp {
	0% {
		transform: translateY(100%);
	}
	50%{
		transform: translateY(-8%);
	}
	65%{
		transform: translateY(4%);
	}
	80%{
		transform: translateY(-4%);
	}
	95%{
		transform: translateY(2%);
	}			
	100% {
		transform: translateY(0%);
	}	
}

@-webkit-keyframes slideUp {
	0% {
		-webkit-transform: translateY(100%);
	}
	50%{
		-webkit-transform: translateY(-8%);
	}
	65%{
		-webkit-transform: translateY(4%);
	}
	80%{
		-webkit-transform: translateY(-4%);
	}
	95%{
		-webkit-transform: translateY(2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
	}	
}

/*
==============================================
slideLeft
==============================================
*/


.slideLeft{
	animation-name: slideLeft;
	-webkit-animation-name: slideLeft;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes slideLeft {
	0% {
		transform: translateX(150%);
	}
	50%{
		transform: translateX(-8%);
	}
	65%{
		transform: translateX(4%);
	}
	80%{
		transform: translateX(-4%);
	}
	95%{
		transform: translateX(2%);
	}			
	100% {
		transform: translateX(0%);
	}
}

@-webkit-keyframes slideLeft {
	0% {
		-webkit-transform: translateX(150%);
	}
	50%{
		-webkit-transform: translateX(-8%);
	}
	65%{
		-webkit-transform: translateX(4%);
	}
	80%{
		-webkit-transform: translateX(-4%);
	}
	95%{
		-webkit-transform: translateX(2%);
	}			
	100% {
		-webkit-transform: translateX(0%);
	}
}

/*
==============================================
slideRight
==============================================
*/


.slideRight{
	animation-name: slideRight;
	-webkit-animation-name: slideRight;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes slideRight {
	0% {
		transform: translateX(-150%);
	}
	50%{
		transform: translateX(8%);
	}
	65%{
		transform: translateX(-4%);
	}
	80%{
		transform: translateX(4%);
	}
	95%{
		transform: translateX(-2%);
	}			
	100% {
		transform: translateX(0%);
	}	
}

@-webkit-keyframes slideRight {
	0% {
		-webkit-transform: translateX(-150%);
	}
	50%{
		-webkit-transform: translateX(8%);
	}
	65%{
		-webkit-transform: translateX(-4%);
	}
	80%{
		-webkit-transform: translateX(4%);
	}
	95%{
		-webkit-transform: translateX(-2%);
	}			
	100% {
		-webkit-transform: translateX(0%);
	}
}

/*
==============================================
slideExpandUp
==============================================
*/


.slideExpandUp{
	animation-name: slideExpandUp;
	-webkit-animation-name: slideExpandUp;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease -out;

	visibility: visible !important;	
}

@keyframes slideExpandUp {
	0% {
		transform: translateY(100%) scaleX(0.5);
	}
	30%{
		transform: translateY(-8%) scaleX(0.5);
	}	
	40%{
		transform: translateY(2%) scaleX(0.5);
	}
	50%{
		transform: translateY(0%) scaleX(1.1);
	}
	60%{
		transform: translateY(0%) scaleX(0.9);		
	}
	70% {
		transform: translateY(0%) scaleX(1.05);
	}			
	80%{
		transform: translateY(0%) scaleX(0.95);		
	}
	90% {
		transform: translateY(0%) scaleX(1.02);
	}	
	100%{
		transform: translateY(0%) scaleX(1);		
	}
}

@-webkit-keyframes slideExpandUp {
	0% {
		-webkit-transform: translateY(100%) scaleX(0.5);
	}
	30%{
		-webkit-transform: translateY(-8%) scaleX(0.5);
	}	
	40%{
		-webkit-transform: translateY(2%) scaleX(0.5);
	}
	50%{
		-webkit-transform: translateY(0%) scaleX(1.1);
	}
	60%{
		-webkit-transform: translateY(0%) scaleX(0.9);		
	}
	70% {
		-webkit-transform: translateY(0%) scaleX(1.05);
	}			
	80%{
		-webkit-transform: translateY(0%) scaleX(0.95);		
	}
	90% {
		-webkit-transform: translateY(0%) scaleX(1.02);
	}	
	100%{
		-webkit-transform: translateY(0%) scaleX(1);		
	}
}

/*
==============================================
expandUp
==============================================
*/


.expandUp{
	animation-name: expandUp;
	-webkit-animation-name: expandUp;	

	animation-duration: 0.7s;	
	-webkit-animation-duration: 0.7s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;		

	visibility: visible !important;	
}

@keyframes expandUp {
	0% {
		transform: translateY(100%) scale(0.6) scaleY(0.5);
	}
	60%{
		transform: translateY(-7%) scaleY(1.12);
	}
	75%{
		transform: translateY(3%);
	}	
	100% {
		transform: translateY(0%) scale(1) scaleY(1);
	}	
}

@-webkit-keyframes expandUp {
	0% {
		-webkit-transform: translateY(100%) scale(0.6) scaleY(0.5);
	}
	60%{
		-webkit-transform: translateY(-7%) scaleY(1.12);
	}
	75%{
		-webkit-transform: translateY(3%);
	}	
	100% {
		-webkit-transform: translateY(0%) scale(1) scaleY(1);
	}	
}

/*
==============================================
fadeIn
==============================================
*/

.fadeIn {
	animation-name: fadeIn;
	-webkit-animation-name: fadeIn;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes fadeIn {
	0% {
		transform: scale(0);
		opacity: 0.0;		
	}
	60% {
		transform: scale(1.1);	
	}
	80% {
		transform: scale(0.9);
		opacity: 1;	
	}	
	100% {
		transform: scale(1);
		opacity: 1;	
	}		
}

@-webkit-keyframes fadeIn {
	0% {
		-webkit-transform: scale(0);
		opacity: 0.0;		
	}
	60% {
		-webkit-transform: scale(1.1);
	}
	80% {
		-webkit-transform: scale(0.9);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(1);
		opacity: 1;	
	}		
}

/*
==============================================
expandOpen
==============================================
*/


.expandOpen{
	animation-name: expandOpen;
	-webkit-animation-name: expandOpen;	

	animation-duration: 1.2s;	
	-webkit-animation-duration: 1.2s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	visibility: visible !important;	
}

@keyframes expandOpen {
	0% {
		transform: scale(1.8);		
	}
	50% {
		transform: scale(0.95);
	}	
	80% {
		transform: scale(1.05);
	}
	90% {
		transform: scale(0.98);
	}	
	100% {
		transform: scale(1);
	}			
}

@-webkit-keyframes expandOpen {
	0% {
		-webkit-transform: scale(1.8);		
	}
	50% {
		-webkit-transform: scale(0.95);
	}	
	80% {
		-webkit-transform: scale(1.05);
	}
	90% {
		-webkit-transform: scale(0.98);
	}	
	100% {
		-webkit-transform: scale(1);
	}					
}

/*
==============================================
bigEntrance
==============================================
*/


.bigEntrance{
	animation-name: bigEntrance;
	-webkit-animation-name: bigEntrance;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	visibility: visible !important;			
}

@keyframes bigEntrance {
	0% {
		transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
		opacity: 0.2;
	}
	30% {
		transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);		
		opacity: 1;
	}
	45% {
		transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	60% {
		transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	75% {
		transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	90% {
		transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	100% {
		transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
		opacity: 1;
	}		
}

@-webkit-keyframes bigEntrance {
	0% {
		-webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
		opacity: 0.2;
	}
	30% {
		-webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);		
		opacity: 1;
	}
	45% {
		-webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	60% {
		-webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	75% {
		-webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	90% {
		-webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	100% {
		-webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
		opacity: 1;
	}				
}

/*
==============================================
hatch
==============================================
*/

.hatch{
	animation-name: hatch;
	-webkit-animation-name: hatch;	

	animation-duration: 2s;	
	-webkit-animation-duration: 2s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;

	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%; 

	visibility: visible !important;		
}

@keyframes hatch {
	0% {
		transform: rotate(0deg) scaleY(0.6);
	}
	20% {
		transform: rotate(-2deg) scaleY(1.05);
	}
	35% {
		transform: rotate(2deg) scaleY(1);
	}
	50% {
		transform: rotate(-2deg);
	}	
	65% {
		transform: rotate(1deg);
	}	
	80% {
		transform: rotate(-1deg);
	}		
	100% {
		transform: rotate(0deg);
	}									
}

@-webkit-keyframes hatch {
	0% {
		-webkit-transform: rotate(0deg) scaleY(0.6);
	}
	20% {
		-webkit-transform: rotate(-2deg) scaleY(1.05);
	}
	35% {
		-webkit-transform: rotate(2deg) scaleY(1);
	}
	50% {
		-webkit-transform: rotate(-2deg);
	}	
	65% {
		-webkit-transform: rotate(1deg);
	}	
	80% {
		-webkit-transform: rotate(-1deg);
	}		
	100% {
		-webkit-transform: rotate(0deg);
	}		
}


/*
==============================================
bounce
==============================================
*/


.bounce{
	animation-name: bounce;
	-webkit-animation-name: bounce;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	
	
	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%; 	
}

@keyframes bounce {
	0% {
		transform: translateY(0%) scaleY(0.6);
	}
	60%{
		transform: translateY(-100%) scaleY(1.1);
	}
	70%{
		transform: translateY(0%) scaleY(0.95) scaleX(1.05);
	}
	80%{
		transform: translateY(0%) scaleY(1.05) scaleX(1);
	}	
	90%{
		transform: translateY(0%) scaleY(0.95) scaleX(1);
	}				
	100%{
		transform: translateY(0%) scaleY(1) scaleX(1);
	}	
}

@-webkit-keyframes bounce {
	0% {
		-webkit-transform: translateY(0%) scaleY(0.6);
	}
	60%{
		-webkit-transform: translateY(-100%) scaleY(1.1);
	}
	70%{
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05);
	}
	80%{
		-webkit-transform: translateY(0%) scaleY(1.05) scaleX(1);
	}	
	90%{
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1);
	}				
	100%{
		-webkit-transform: translateY(0%) scaleY(1) scaleX(1);
	}		
}


/*
==============================================
pulse
==============================================
*/

.pulse{
	animation-name: pulse;
	-webkit-animation-name: pulse;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		opacity: 0.7;		
	}
	50% {
		transform: scale(1);
		opacity: 1;	
	}	
	100% {
		transform: scale(0.95);
		opacity: 0.7;	
	}			
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.95);
		opacity: 0.7;		
	}
	50% {
		-webkit-transform: scale(1);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(0.95);
		opacity: 0.7;	
	}			
}

/*
==============================================
floating
==============================================
*/

.floating{
	animation-name: floating;
	-webkit-animation-name: floating;

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes floating {
	0% {
		transform: translateY(0%);	
	}
	50% {
		transform: translateY(8%);	
	}	
	100% {
		transform: translateY(0%);
	}			
}

@-webkit-keyframes floating {
	0% {
		-webkit-transform: translateY(0%);	
	}
	50% {
		-webkit-transform: translateY(8%);	
	}	
	100% {
		-webkit-transform: translateY(0%);
	}			
}

/*
==============================================
tossing
==============================================
*/

.tossing{
	animation-name: tossing;
	-webkit-animation-name: tossing;	

	animation-duration: 2.5s;	
	-webkit-animation-duration: 2.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes tossing {
	0% {
		transform: rotate(-4deg);	
	}
	50% {
		transform: rotate(4deg);
	}
	100% {
		transform: rotate(-4deg);	
	}						
}

@-webkit-keyframes tossing {
	0% {
		-webkit-transform: rotate(-4deg);	
	}
	50% {
		-webkit-transform: rotate(4deg);
	}
	100% {
		-webkit-transform: rotate(-4deg);	
	}				
}

/*
==============================================
pullUp
==============================================
*/

.pullUp{
	animation-name: pullUp;
	-webkit-animation-name: pullUp;	

	animation-duration: 1.1s;	
	-webkit-animation-duration: 1.1s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%; 		
}

@keyframes pullUp {
	0% {
		transform: scaleY(0.1);
	}
	40% {
		transform: scaleY(1.02);
	}
	60% {
		transform: scaleY(0.98);
	}
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(0.98);
	}				
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(1);
	}							
}

@-webkit-keyframes pullUp {
	0% {
		-webkit-transform: scaleY(0.1);
	}
	40% {
		-webkit-transform: scaleY(1.02);
	}
	60% {
		-webkit-transform: scaleY(0.98);
	}
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(0.98);
	}				
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(1);
	}		
}

/*
==============================================
pullDown
==============================================
*/

.pullDown{
	animation-name: pullDown;
	-webkit-animation-name: pullDown;	

	animation-duration: 1.1s;	
	-webkit-animation-duration: 1.1s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 50% 0%;
	-ms-transform-origin: 50% 0%;
	-webkit-transform-origin: 50% 0%; 		
}

@keyframes pullDown {
	0% {
		transform: scaleY(0.1);
	}
	40% {
		transform: scaleY(1.02);
	}
	60% {
		transform: scaleY(0.98);
	}
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(0.98);
	}				
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(1);
	}							
}

@-webkit-keyframes pullDown {
	0% {
		-webkit-transform: scaleY(0.1);
	}
	40% {
		-webkit-transform: scaleY(1.02);
	}
	60% {
		-webkit-transform: scaleY(0.98);
	}
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(0.98);
	}				
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(1);
	}		
}

/*
==============================================
stretchLeft
==============================================
*/

.stretchLeft{
	animation-name: stretchLeft;
	-webkit-animation-name: stretchLeft;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 100% 0%;
	-ms-transform-origin: 100% 0%;
	-webkit-transform-origin: 100% 0%; 
}

@keyframes stretchLeft {
	0% {
		transform: scaleX(0.3);
	}
	40% {
		transform: scaleX(1.02);
	}
	60% {
		transform: scaleX(0.98);
	}
	80% {
		transform: scaleX(1.01);
	}
	100% {
		transform: scaleX(0.98);
	}				
	80% {
		transform: scaleX(1.01);
	}
	100% {
		transform: scaleX(1);
	}							
}

@-webkit-keyframes stretchLeft {
	0% {
		-webkit-transform: scaleX(0.3);
	}
	40% {
		-webkit-transform: scaleX(1.02);
	}
	60% {
		-webkit-transform: scaleX(0.98);
	}
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(0.98);
	}				
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(1);
	}		
}

/*
==============================================
stretchRight
==============================================
*/

.stretchRight{
	animation-name: stretchRight;
	-webkit-animation-name: stretchRight;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 0% 0%;
	-ms-transform-origin: 0% 0%;
	-webkit-transform-origin: 0% 0%; 		
}

@keyframes stretchRight {
	0% {
		transform: scaleX(0.3);
	}
	40% {
		transform: scaleX(1.02);
	}
	60% {
		transform: scaleX(0.98);
	}
	80% {
		transform: scaleX(1.01);
	}
	100% {
		transform: scaleX(0.98);
	}				
	80% {
		transform: scaleX(1.01);
	}
	100% {
		transform: scaleX(1);
	}							
}

@-webkit-keyframes stretchRight {
	0% {
		-webkit-transform: scaleX(0.3);
	}
	40% {
		-webkit-transform: scaleX(1.02);
	}
	60% {
		-webkit-transform: scaleX(0.98);
	}
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(0.98);
	}				
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(1);
	}		
}



.animated{-webkit-animation-fill-mode:both;-moz-animation-fill-mode:both;-ms-animation-fill-mode:both;-o-animation-fill-mode:both;animation-fill-mode:both;-webkit-animation-duration:1s;-moz-animation-duration:1s;-ms-animation-duration:1s;-o-animation-duration:1s;animation-duration:1s;}.animated.hinge{-webkit-animation-duration:1s;-moz-animation-duration:1s;-ms-animation-duration:1s;-o-animation-duration:1s;animation-duration:1s;}@-webkit-keyframes fadeIn {
	0% {opacity: 0;}	100% {opacity: 1;}
}
/*
@-moz-keyframes fadeIn {
	0% {opacity: 0;}	
	100% {opacity: 1;}
}

@-o-keyframes fadeIn {
	0% {opacity: 0;}	
	100% {opacity: 1;}
}

@keyframes fadeIn {
	0% {opacity: 0;}	
	100% {opacity: 1;}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	-moz-animation-name: fadeIn;
	-o-animation-name: fadeIn;
	animation-name: fadeIn;
}
*/
@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(20px);
	}
	
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes fadeInUp {
	0% {
		opacity: 0;
		-moz-transform: translateY(20px);
	}
	
	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes fadeInUp {
	0% {
		opacity: 0;
		-o-transform: translateY(20px);
	}
	
	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	-moz-animation-name: fadeInUp;
	-o-animation-name: fadeInUp;
	animation-name: fadeInUp;
}
@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-20px);
	}
	
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes fadeInDown {
	0% {
		opacity: 0;
		-moz-transform: translateY(-20px);
	}
	
	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes fadeInDown {
	0% {
		opacity: 0;
		-o-transform: translateY(-20px);
	}
	
	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}
	
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-o-animation-name: fadeInDown;
	animation-name: fadeInDown;
}
@-webkit-keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-20px);
	}
	
	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes fadeInLeft {
	0% {
		opacity: 0;
		-moz-transform: translateX(-20px);
	}
	
	100% {
		opacity: 1;
		-moz-transform: translateX(0);
	}
}

@-o-keyframes fadeInLeft {
	0% {
		opacity: 0;
		-o-transform: translateX(-20px);
	}
	
	100% {
		opacity: 1;
		-o-transform: translateX(0);
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translateX(-20px);
	}
	
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	-moz-animation-name: fadeInLeft;
	-o-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(20px);
	}
	
	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes fadeInRight {
	0% {
		opacity: 0;
		-moz-transform: translateX(20px);
	}
	
	100% {
		opacity: 1;
		-moz-transform: translateX(0);
	}
}

@-o-keyframes fadeInRight {
	0% {
		opacity: 0;
		-o-transform: translateX(20px);
	}
	
	100% {
		opacity: 1;
		-o-transform: translateX(0);
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		transform: translateX(20px);
	}
	
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.fadeInRight {
	-webkit-animation-name: fadeInRight;
	-moz-animation-name: fadeInRight;
	-o-animation-name: fadeInRight;
	animation-name: fadeInRight;
}
@-webkit-keyframes fadeInUpBig {
	0% {
		opacity: 0;
		-webkit-transform: translateY(2000px);
	}
	
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes fadeInUpBig {
	0% {
		opacity: 0;
		-moz-transform: translateY(2000px);
	}
	
	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes fadeInUpBig {
	0% {
		opacity: 0;
		-o-transform: translateY(2000px);
	}
	
	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes fadeInUpBig {
	0% {
		opacity: 0;
		transform: translateY(2000px);
	}
	
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.fadeInUpBig {
	-webkit-animation-name: fadeInUpBig;
	-moz-animation-name: fadeInUpBig;
	-o-animation-name: fadeInUpBig;
	animation-name: fadeInUpBig;
}
@-webkit-keyframes fadeInDownBig {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-2000px);
	}
	
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes fadeInDownBig {
	0% {
		opacity: 0;
		-moz-transform: translateY(-2000px);
	}
	
	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-o-keyframes fadeInDownBig {
	0% {
		opacity: 0;
		-o-transform: translateY(-2000px);
	}
	
	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes fadeInDownBig {
	0% {
		opacity: 0;
		transform: translateY(-2000px);
	}
	
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.fadeInDownBig {
	-webkit-animation-name: fadeInDownBig;
	-moz-animation-name: fadeInDownBig;
	-o-animation-name: fadeInDownBig;
	animation-name: fadeInDownBig;
}
@-webkit-keyframes fadeInLeftBig {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-2000px);
	}
	
	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}
@-moz-keyframes fadeInLeftBig {
	0% {
		opacity: 0;
		-moz-transform: translateX(-2000px);
	}
	
	100% {
		opacity: 1;
		-moz-transform: translateX(0);
	}
}
@-o-keyframes fadeInLeftBig {
	0% {
		opacity: 0;
		-o-transform: translateX(-2000px);
	}
	
	100% {
		opacity: 1;
		-o-transform: translateX(0);
	}
}
@keyframes fadeInLeftBig {
	0% {
		opacity: 0;
		transform: translateX(-2000px);
	}
	
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.fadeInLeftBig {
	-webkit-animation-name: fadeInLeftBig;
	-moz-animation-name: fadeInLeftBig;
	-o-animation-name: fadeInLeftBig;
	animation-name: fadeInLeftBig;
}
@-webkit-keyframes fadeInRightBig {
	0% {
		opacity: 0;
		-webkit-transform: translateX(2000px);
	}
	
	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes fadeInRightBig {
	0% {
		opacity: 0;
		-moz-transform: translateX(2000px);
	}
	
	100% {
		opacity: 1;
		-moz-transform: translateX(0);
	}
}

@-o-keyframes fadeInRightBig {
	0% {
		opacity: 0;
		-o-transform: translateX(2000px);
	}
	
	100% {
		opacity: 1;
		-o-transform: translateX(0);
	}
}

@keyframes fadeInRightBig {
	0% {
		opacity: 0;
		transform: translateX(2000px);
	}
	
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.fadeInRightBig {
	-webkit-animation-name: fadeInRightBig;
	-moz-animation-name: fadeInRightBig;
	-o-animation-name: fadeInRightBig;
	animation-name: fadeInRightBig;
}
@-webkit-keyframes fadeOut {
	0% {opacity: 1;}
	100% {opacity: 0;}
}

@-moz-keyframes fadeOut {
	0% {opacity: 1;}
	100% {opacity: 0;}
}

@-o-keyframes fadeOut {
	0% {opacity: 1;}
	100% {opacity: 0;}
}

@keyframes fadeOut {
	0% {opacity: 1;}
	100% {opacity: 0;}
}

.fadeOut {
	-webkit-animation-name: fadeOut;
	-moz-animation-name: fadeOut;
	-o-animation-name: fadeOut;
	animation-name: fadeOut;
}
@-webkit-keyframes fadeOutUp {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		-webkit-transform: translateY(-20px);
	}
}
@-moz-keyframes fadeOutUp {
	0% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		-moz-transform: translateY(-20px);
	}
}
@-o-keyframes fadeOutUp {
	0% {
		opacity: 1;
		-o-transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		-o-transform: translateY(-20px);
	}
}
@keyframes fadeOutUp {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		transform: translateY(-20px);
	}
}

.fadeOutUp {
	-webkit-animation-name: fadeOutUp;
	-moz-animation-name: fadeOutUp;
	-o-animation-name: fadeOutUp;
	animation-name: fadeOutUp;
}
@-webkit-keyframes fadeOutDown {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		-webkit-transform: translateY(20px);
	}
}

@-moz-keyframes fadeOutDown {
	0% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		-moz-transform: translateY(20px);
	}
}

@-o-keyframes fadeOutDown {
	0% {
		opacity: 1;
		-o-transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		-o-transform: translateY(20px);
	}
}

@keyframes fadeOutDown {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		transform: translateY(20px);
	}
}

.fadeOutDown {
	-webkit-animation-name: fadeOutDown;
	-moz-animation-name: fadeOutDown;
	-o-animation-name: fadeOutDown;
	animation-name: fadeOutDown;
}
@-webkit-keyframes fadeOutLeft {
	0% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-webkit-transform: translateX(-20px);
	}
}

@-moz-keyframes fadeOutLeft {
	0% {
		opacity: 1;
		-moz-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-moz-transform: translateX(-20px);
	}
}

@-o-keyframes fadeOutLeft {
	0% {
		opacity: 1;
		-o-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-o-transform: translateX(-20px);
	}
}

@keyframes fadeOutLeft {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		transform: translateX(-20px);
	}
}

.fadeOutLeft {
	-webkit-animation-name: fadeOutLeft;
	-moz-animation-name: fadeOutLeft;
	-o-animation-name: fadeOutLeft;
	animation-name: fadeOutLeft;
}
@-webkit-keyframes fadeOutRight {
	0% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-webkit-transform: translateX(20px);
	}
}

@-moz-keyframes fadeOutRight {
	0% {
		opacity: 1;
		-moz-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-moz-transform: translateX(20px);
	}
}

@-o-keyframes fadeOutRight {
	0% {
		opacity: 1;
		-o-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-o-transform: translateX(20px);
	}
}

@keyframes fadeOutRight {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		transform: translateX(20px);
	}
}

.fadeOutRight {
	-webkit-animation-name: fadeOutRight;
	-moz-animation-name: fadeOutRight;
	-o-animation-name: fadeOutRight;
	animation-name: fadeOutRight;
}
@-webkit-keyframes fadeOutUpBig {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		-webkit-transform: translateY(-2000px);
	}
}

@-moz-keyframes fadeOutUpBig {
	0% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		-moz-transform: translateY(-2000px);
	}
}

@-o-keyframes fadeOutUpBig {
	0% {
		opacity: 1;
		-o-transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		-o-transform: translateY(-2000px);
	}
}

@keyframes fadeOutUpBig {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		transform: translateY(-2000px);
	}
}

.fadeOutUpBig {
	-webkit-animation-name: fadeOutUpBig;
	-moz-animation-name: fadeOutUpBig;
	-o-animation-name: fadeOutUpBig;
	animation-name: fadeOutUpBig;
}
@-webkit-keyframes fadeOutDownBig {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		-webkit-transform: translateY(2000px);
	}
}

@-moz-keyframes fadeOutDownBig {
	0% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		-moz-transform: translateY(2000px);
	}
}

@-o-keyframes fadeOutDownBig {
	0% {
		opacity: 1;
		-o-transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		-o-transform: translateY(2000px);
	}
}

@keyframes fadeOutDownBig {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	
	100% {
		opacity: 0;
		transform: translateY(2000px);
	}
}

.fadeOutDownBig {
	-webkit-animation-name: fadeOutDownBig;
	-moz-animation-name: fadeOutDownBig;
	-o-animation-name: fadeOutDownBig;
	animation-name: fadeOutDownBig;
}
@-webkit-keyframes fadeOutLeftBig {
	0% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-webkit-transform: translateX(-2000px);
	}
}

@-moz-keyframes fadeOutLeftBig {
	0% {
		opacity: 1;
		-moz-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-moz-transform: translateX(-2000px);
	}
}

@-o-keyframes fadeOutLeftBig {
	0% {
		opacity: 1;
		-o-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-o-transform: translateX(-2000px);
	}
}

@keyframes fadeOutLeftBig {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		transform: translateX(-2000px);
	}
}

.fadeOutLeftBig {
	-webkit-animation-name: fadeOutLeftBig;
	-moz-animation-name: fadeOutLeftBig;
	-o-animation-name: fadeOutLeftBig;
	animation-name: fadeOutLeftBig;
}
@-webkit-keyframes fadeOutRightBig {
	0% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-webkit-transform: translateX(2000px);
	}
}
@-moz-keyframes fadeOutRightBig {
	0% {
		opacity: 1;
		-moz-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-moz-transform: translateX(2000px);
	}
}
@-o-keyframes fadeOutRightBig {
	0% {
		opacity: 1;
		-o-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-o-transform: translateX(2000px);
	}
}
@keyframes fadeOutRightBig {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		transform: translateX(2000px);
	}
}

.fadeOutRightBig {
	-webkit-animation-name: fadeOutRightBig;
	-moz-animation-name: fadeOutRightBig;
	-o-animation-name: fadeOutRightBig;
	animation-name: fadeOutRightBig;
}
@-webkit-keyframes slideInDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-2000px);
	}

	100% {
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes slideInDown {
	0% {
		opacity: 0;
		-moz-transform: translateY(-2000px);
	}

	100% {
		-moz-transform: translateY(0);
	}
}

@-o-keyframes slideInDown {
	0% {
		opacity: 0;
		-o-transform: translateY(-2000px);
	}

	100% {
		-o-transform: translateY(0);
	}
}

@keyframes slideInDown {
	0% {
		opacity: 0;
		transform: translateY(-2000px);
	}

	100% {
		transform: translateY(0);
	}
}

.slideInDown {
	-webkit-animation-name: slideInDown;
	-moz-animation-name: slideInDown;
	-o-animation-name: slideInDown;
	animation-name: slideInDown;
}
@-webkit-keyframes slideInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-100%);
	}
	
	100% {
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes slideInLeft {
	0% {
		opacity: 0;
		-moz-transform: translateX(-100%);
	}
	
	100% {
		-moz-transform: translateX(0);
	}
}

@-o-keyframes slideInLeft {
	0% {
		opacity: 0;
		-o-transform: translateX(-100%);
	}
	
	100% {
		-o-transform: translateX(0);
	}
}

@keyframes slideInLeft {
	0% {
		opacity: 0;
		transform: translateX(-100%);
	}
	
	100% {
		transform: translateX(0);
	}
}

.slideInLeft {
	-webkit-animation-name: slideInLeft;
	-moz-animation-name: slideInLeft;
	-o-animation-name: slideInLeft;
	animation-name: slideInLeft;
}
@-webkit-keyframes slideInRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(100%);
	}
	
	100% {
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes slideInRight {
	0% {
		opacity: 0;
		-moz-transform: translateX(100%);
	}
	
	100% {
		-moz-transform: translateX(0);
	}
}

@-o-keyframes slideInRight {
	0% {
		opacity: 0;
		-o-transform: translateX(100%);
	}
	
	100% {
		-o-transform: translateX(0);
	}
}

@keyframes slideInRight {
	0% {
		opacity: 0;
		transform: translateX(100%);
	}
	
	100% {
		transform: translateX(0);
	}
}

.slideInRight {
	-webkit-animation-name: slideInRight;
	-moz-animation-name: slideInRight;
	-o-animation-name: slideInRight;
	animation-name: slideInRight;
}
@-webkit-keyframes slideOutUp {
	0% {
		-webkit-transform: translateY(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(-2000px);
	}
}

@-moz-keyframes slideOutUp {
	0% {
		-moz-transform: translateY(0);
	}

	100% {
		opacity: 0;
		-moz-transform: translateY(-2000px);
	}
}

@-o-keyframes slideOutUp {
	0% {
		-o-transform: translateY(0);
	}

	100% {
		opacity: 0;
		-o-transform: translateY(-2000px);
	}
}

@keyframes slideOutUp {
	0% {
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		transform: translateY(-2000px);
	}
}

.slideOutUp {
	-webkit-animation-name: slideOutUp;
	-moz-animation-name: slideOutUp;
	-o-animation-name: slideOutUp;
	animation-name: slideOutUp;
}
@-webkit-keyframes slideOutLeft {
	0% {
		-webkit-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-webkit-transform: translateX(-2000px);
	}
}

@-moz-keyframes slideOutLeft {
	0% {
		-moz-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-moz-transform: translateX(-2000px);
	}
}

@-o-keyframes slideOutLeft {
	0% {
		-o-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-o-transform: translateX(-2000px);
	}
}

@keyframes slideOutLeft {
	0% {
		transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		transform: translateX(-2000px);
	}
}

.slideOutLeft {
	-webkit-animation-name: slideOutLeft;
	-moz-animation-name: slideOutLeft;
	-o-animation-name: slideOutLeft;
	animation-name: slideOutLeft;
}
@-webkit-keyframes slideOutRight {
	0% {
		-webkit-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-webkit-transform: translateX(2000px);
	}
}

@-moz-keyframes slideOutRight {
	0% {
		-moz-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-moz-transform: translateX(2000px);
	}
}

@-o-keyframes slideOutRight {
	0% {
		-o-transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		-o-transform: translateX(2000px);
	}
}

@keyframes slideOutRight {
	0% {
		transform: translateX(0);
	}
	
	100% {
		opacity: 0;
		transform: translateX(2000px);
	}
}

.slideOutRight {
	-webkit-animation-name: slideOutRight;
	-moz-animation-name: slideOutRight;
	-o-animation-name: slideOutRight;
	animation-name: slideOutRight;
}

@-webkit-keyframes moveFromLeft{
    from {
        -webkit-transform: translateX(-100%);
    }
    to {
        -webkit-transform: translateX(0%);
    }
}
@-moz-keyframes moveFromLeft{
    from {
        -moz-transform: translateX(-100%);
    }
    to {
        -moz-transform: translateX(0%);
    }
}
@-ms-keyframes moveFromLeft{
    from {
        -ms-transform: translateX(-100%);
    }
    to {
        -ms-transform: translateX(0%);
    }
}
@-o-keyframes moveFromLeft{
    from {
        -o-transform: translateX(-100%);
    }
    to {
        -o-transform: translateX(0%);
    }
}
@keyframes moveFromLeft{
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
}

.moveFromLeft {
    -webkit-animation: moveFromLeft 400ms ease;
    -moz-animation: moveFromLeft 400ms ease;
    -ms-animation: moveFromLeft 400ms ease;
    -o-animation: moveFromLeft 400ms ease;
    animation: moveFromLeft 400ms ease;
}

@-webkit-keyframes moveFromRight {
    from {
        -webkit-transform: translateX(100%);
    }
    to {
        -webkit-transform: translateX(0%);
    }
}
@-moz-keyframes moveFromRight {
    from {
        -moz-transform: translateX(100%);
    }
    to {
        -moz-transform: translateX(0%);
    }
}
@-ms-keyframes moveFromRight {
    from {
        -ms-transform: translateX(100%);
    }
    to {
        -ms-transform: translateX(0%);
    }
}
@-o-keyframes moveFromRight {
    from {
        -o-transform: translateX(100%);
    }
    to {
        -o-transform: translateX(0%);
    }
}
@keyframes moveFromRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
}
.moveFromRight {
    -webkit-animation: moveFromRight 400ms ease;
    -moz-animation: moveFromRight 400ms ease;
    -ms-animation: moveFromRight 400ms ease;
    -o-animation: moveFromRight 400ms ease;
    animation: moveFromRight 400ms ease;
}

@-webkit-keyframes moveFromBottom {
    from {
        -webkit-transform: translateY(100%);
    }
    to {
        -webkit-transform: translateY(0%);
    }
}
@-moz-keyframes moveFromBottom {
    from {
        -moz-transform: translateY(100%);
    }
    to {
        -moz-transform: translateY(0%);
    }
}
@-ms-keyframes moveFromBottom {
    from {
        -ms-transform: translateY(100%);
    }
    to {
        -ms-transform: translateY(0%);
    }
}
@-o-keyframes moveFromBottom {
    from {
        -o-transform: translateY(100%);
    }
    to {
        -o-transform: translateY(0%);
    }
}
@keyframes moveFromBottom {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0%);
    }
}
.moveFromBottom {
    -webkit-animation: moveFromBottom 400ms ease;
    -moz-animation: moveFromBottom 400ms ease;
    -ms-animation: moveFromBottom 400ms ease;
    -o-animation: moveFromBottom 400ms ease;
    animation: moveFromBottom 400ms ease;
}


@-webkit-keyframes scaleDisappear {
    0% {
    	opacity: 1;
        -webkit-transform: scale(1);
    }
    60% {
    	opacity: 0;
        -webkit-transform: scale(1.3);
    }
    100% {
    	opacity: 1;
        -webkit-transform: scale(1);
    }
}
@-moz-keyframes scaleDisappear {
    0% {
    	opacity: 1;
        -moz-transform: scale(1);
    }
    60% {
    	opacity: 0;
        -moz-transform: scale(1.3);
    }
    100% {
    	opacity: 1;
        -moz-transform: scale(1);
    }
}
@-ms-keyframes scaleDisappear {
    0% {
    	opacity: 1;
        -ms-transform: scale(1);
    }
    60% {
    	opacity: 0;
        -ms-transform: scale(1.3);
    }
    100% {
    	opacity: 1;
        -ms-transform: scale(1);
    }
}
@-o-keyframes scaleDisappear {
    0% {
    	opacity: 1;
        -o-transform: scale(1);
    }
    60% {
    	opacity: 0;
        -o-transform: scale(1.3);
    }
    100% {
    	opacity: 1;
        -o-transform: scale(1);
    }
}
@keyframes scaleDisappear {
    0% {
    	opacity: 1;
        transform: scale(1);
    }
    60% {
    	opacity: 0;
        transform: scale(1.3);
    }
    100% {
    	opacity: 1;
        transform: scale(1);
    }
}
.scaleDisappear {
    -webkit-animation: scaleDisappear 350ms ease-in-out;
    -moz-animation: scaleDisappear 350ms ease-in-out;
    -ms-animation: scaleDisappear 350ms ease-in-out;
    -o-animation: scaleDisappear 350ms ease-in-out;
    animation: scaleDisappear 350ms ease-in-out;
}


@-webkit-keyframes scaleAppear {
    0% {
    	opacity: 0;
        -webkit-transform: scale(0);
    }
    100% {
    	opacity: 1;
        -webkit-transform: scale(1);
    }
}
@-moz-keyframes scaleAppear {
    0% {
    	opacity: 0;
        -moz-transform: scale(0);
    }
    100% {
    	opacity: 1;
        -moz-transform: scale(1);
    }
}
@-ms-keyframes scaleAppear {
    0% {
    	opacity: 0;
        -ms-transform: scale(0);
    }
    100% {
    	opacity: 1;
        -ms-transform: scale(1);
    }
}
@-o-keyframes scaleAppear {
    0% {
    	opacity: 0;
        -o-transform: scale(0);
    }
    100% {
    	opacity: 1;
        -o-transform: scale(1);
    }
}
@keyframes scaleAppear {
    0% {
    	opacity: 0;
        transform: scale(0);
    }
    100% {
    	opacity: 1;
        transform: scale(1);
    }
}
.scaleAppear {
    -webkit-animation: scaleAppear 750ms ease;
    -moz-animation: scaleAppear 750ms ease;
    -ms-animation: scaleAppear 750ms ease;
    -o-animation: scaleAppear 750ms ease;
    animation: scaleAppear 750ms ease;
}

/*
   Animation example, for spinners
*/
.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}